import PlaceSelectors from "../features/stations/components/PlaceSelectors.tsx";
import PassengerPicker from "../features/passengers/components/PassengerPicker.tsx";
import DateTimeField from "../features/dates/components/DateTimeField.tsx";
import HeroBackgroundMain from "../features/hero/components/HeroBackgroundMain.tsx";
import useFormMethods from "./../hooks/useFormMethods";
import useIsMobile from "../../../hooks/selector/useIsMobile.ts";
import MobilePlaceInputButton from "../features/stations/components/MobilePlaceInputButton.tsx";
import Translation from "../../../features/translation/components/Translation.tsx";
import LoaderButton from "../../../components/generic/loaders/LoaderButton.tsx";
import useSubmitSearch from "../hooks/useSubmitSearch.ts";
import ReturnDateTimeField from "../features/dates/components/ReturnDateTimeField.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";


function SearchForm() {
    const isMobile = useIsMobile()
    const {setClassName} = useFormMethods();
    const {submitForm, isFetching} = useSubmitSearch()

    const {
        fromPlace,
        toPlace,
    } = useAppSelector((state) => (state.form))

    return (
        <div className={setClassName()}>
            <HeroBackgroundMain/>
            <div className="gb--search-form">
                {isMobile ? (
                    <>
                        <MobilePlaceInputButton
                            direction="departure"
                            showIsIncomplete={!!fromPlace && !fromPlace.placeId}
                        />
                        <MobilePlaceInputButton
                            direction="destination"
                            showIsIncomplete={!!toPlace && !toPlace?.placeId}
                        />
                    </>
                ) : (
                    <PlaceSelectors showIsIncomplete/>
                )}
                <div className={"search__select search__select--datepicker gb--row gb--row-middle"}>
                    <DateTimeField/>
                    <ReturnDateTimeField/>
                </div>
                <PassengerPicker/>
                {isFetching ? (
                    <LoaderButton
                        style={{}}
                        className='btn btn--green search__select--action'
                    />
                ) : (
                    <button
                        style={{margin: 0}}
                        className='btn btn--green search__select--action'
                        onClick={submitForm}
                    >
                        <Translation
                            translationKey='search.form.button'
                            styleText="upperCase"
                        />
                    </button>
                )}
            </div>
        </div>
    )
}

export default SearchForm;