import IconLocationMarker from "../../../../../components/generic/icons/IconLocationMarker.tsx";
import useMobileStationInput from "../hooks/useMobileStationInput.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import FadingError from "../../../../../features/errors/components/FadingError.tsx";
import ValidPlaceIndicator from "./ValidPlaceIndicator.tsx";

interface MobileStationInputButtonProps {
    direction: "departure" | "destination",
    showIsValid?: boolean
    isValid?: boolean,
    showIsIncomplete?: boolean,
}

function MobilePlaceInputButton(props: MobileStationInputButtonProps) {
    const {
        direction,
        showIsValid,
        isValid,
        showIsIncomplete,
    } = props

    const {
        className,
        onClick,
        directionLabel,
        place,
        direction: directionKey,
        errorMessage,
        error,
        submitTimestamp,
    } = useMobileStationInput(direction)

    const cityAirportOrStation = useTranslation(`search.form.${directionKey}`) as string

    return (
        <div
            className={`${className} ${showIsIncomplete ? "search__select--incomplete-address-border" : ""}`}
            onClick={onClick}
            role="button"
        >
            {showIsIncomplete && (
                <div className="search__select--incomplete-address-container">
                    <div className="search__select--incomplete-address">
                        Update address
                    </div>
                </div>
            )}
            <span className="search__select--label gb--emphasize gb--emphasize-default">
                {directionLabel}
            </span>
            <IconLocationMarker
                className="search__select--icon"
                height="14"
            />
            <input
                placeholder={cityAirportOrStation}
                readOnly
                type="text"
                value={place?.placeName ?? ''}
            />
            {showIsValid && (
                <ValidPlaceIndicator
                    isValid={isValid!}
                />
            )}
            <FadingError
                isError={error}
                showError={submitTimestamp}
                errorMessageTranslationKey={errorMessage}
            />
        </div>
    )
}

export default MobilePlaceInputButton