import {useLazyGetFlightNumberQuery} from "../../../../../api/reducers/apiSlice.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";

export const useFlightNumber = () => {
    const [query, response] = useLazyGetFlightNumberQuery()
    const {
        departureDate,
        returnDate
    } = useAppSelector((state) => state?.form)

    function separateFlightNumber(flightNumber) {
        const matches = flightNumber.match(/^([A-Za-z]+)(\d*)$/);
        if (matches) {
            const [, airlineCode, flightNum] = matches;
            return { airlineCode, flightNum: flightNum || '' }; // Set flightNum to null if it's an empty string
        } else {
            return null; // Invalid format
        }
    }

    const getFlightDirection = (pickUpInfo, dropOffInfo) => {
        if (pickUpInfo.isAirport && dropOffInfo.isAirport) {
            return {
                direction: 'arriving',
                iataCode: pickUpInfo.iataCode
            }
        } else if (pickUpInfo.isAirport) {
            return {
                direction: 'arriving',
                iataCode: pickUpInfo.iataCode
            }
        } else if (dropOffInfo.isAirport) {
            return {
                direction: 'departing',
                iataCode: dropOffInfo.iataCode
            }
        } else {
            return null;
        }
    }

    const getFlights = (searchTerm: string, data: any, isReturn: boolean = false) => {
        if (data) {
            // const { airlineCode, flightNum } = separateFlightNumber(searchTerm);

            const {direction, iataCode} = isReturn
                ? getFlightDirection(data.dropOffInfo, data.pickupInfo)
                : getFlightDirection(data.pickupInfo, data.dropOffInfo)

            const datetime = isReturn
                ? returnDate
                : departureDate

            // if (airlineCode) {
                query({
                    // airline_code: airlineCode,
                    flight_number: searchTerm,
                    airport_code: iataCode,
                    flight_direction: direction,
                    pickup_datetime: datetime
                })
            // }
        }
    }

    return {
        response,
        getFlights
    }
}