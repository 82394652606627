import CollapsableComponent from "../../../../../components/generic/CollapsableComponent.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";
import Row from "../../../../../features/containers/components/Row.tsx";
import useDisplayDuration from "../hooks/useDisplayDuration.ts";
import useSearchResultsTripInfo from "../hooks/useSearchResultsTripInfo.ts";
import PillLabel from "../../../../../components/generic/PillLabel.tsx";
import useFormPlaceValidator from "../../../hooks/useFormPlaceValidator.ts";

interface SearchResultsInfoProps {
    label: string,
    duration: number,
    pickUpInfo: any,
    dropOffInfo: any
}

const TripInfo = (props: SearchResultsInfoProps) => {
    const {
        label,
        duration,
        dropOffInfo,
        pickUpInfo
    } = props

    const durationLabel = useDisplayDuration(duration)
    const {
        departure,
        destination,
        startDateDisplay,
        returnDateDisplay,
    } = useSearchResultsTripInfo(duration)

    const {
        lastSearchInvalidDeparture,
        lastSearchInvalidDestination,
    } = useFormPlaceValidator()

    const departureDisplay = pickUpInfo?.isAirport ? pickUpInfo?.pickUpPlace : departure;
    const departureValid = pickUpInfo?.isAirport ? !pickUpInfo?.isAirport : lastSearchInvalidDeparture;

    const destinationDisplay = dropOffInfo?.isAirport ? dropOffInfo?.dropOffPlace : destination;
    const destinationValid = dropOffInfo?.isAirport ? !dropOffInfo?.isAirport : lastSearchInvalidDestination;

    return (
        <CollapsableComponent
            label={label}
            wrapper
            hrClassName={'gb--divider__hr'}
        >
            <Column style={{width: "100%"}}>
                <Row className="gb--booking__info">
                    <Column style={{width: "100%"}}>
                        <div className="gb--booking__date">{startDateDisplay}</div>
                        <Row
                            className="gb--booking__location"
                            justify
                        >
                            <div className={`${departureValid ? 'gb--booking__location-label' : ''}`}>
                                {departureDisplay}
                            </div>
                            {departureValid && (
                                <PillLabel
                                    color="red"
                                    label="Incomplete address"
                                />
                            )}
                        </Row>
                    </Column>
                </Row>
                <Row className="gb--booking__info">
                    <div className="gb--booking__direction">
                        <hr className="gb--booking__connection"/>
                    </div>
                    <div className="gb--booking__estimate">
                        {durationLabel}
                    </div>
                </Row>
                <Row>
                    <Column style={{width: "100%"}}>
                        <div className="gb--booking__date">{returnDateDisplay}</div>
                        <Row
                            className="gb--booking__location"
                            justify
                        >
                            <div className={`${destinationValid ? 'gb--booking__location-label' : ''}`}>
                                {destinationDisplay}
                            </div>
                            {destinationValid && (
                                <PillLabel
                                    color="red"
                                    label="Incomplete address"
                                />
                            )}
                        </Row>
                    </Column>
                </Row>
            </Column>
        </CollapsableComponent>
    )
}

export default TripInfo
