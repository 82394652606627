import {useEffect} from "react";
import shouldFetchMozioData from "../functions/shouldFetchMozioData.ts";
import useLocale from "../../../../../hooks/selector/useLocale.ts";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import {useLazyPollMozioResultsQuery} from "../../../../../api/reducers/apiSlice.ts";
import {InitialSearchRequestType} from "../../../../../api/types/InitialSearchRequest.type.ts";

export default function useGetFirstBatchOfMozioData(gbtDataIsLoading: boolean, gbtDataIsError: FetchBaseQueryError | SerializedError | undefined, gbtVehiclesData: any, alreadyFetchedData: any, originalArgs: InitialSearchRequestType) {
    const [
        getMozioVehiclesData,
        {
            data,
            isLoading,
            isUninitialized,
        }
    ] = useLazyPollMozioResultsQuery()
    const locale = useLocale();

    useEffect(() => {
        if (shouldFetchMozioData(gbtDataIsLoading, gbtDataIsError, gbtVehiclesData) && !alreadyFetchedData) {
            getMozioVehiclesData({
                searchId: gbtVehiclesData?.data?.mozioSearchId,
                locale,
            })
        }
    }, [gbtVehiclesData, gbtDataIsLoading, alreadyFetchedData]);

    return {
        data,
        isLoading,
        isUninitialized,
    }
}