import Portal from "../../../../components/generic/portal/Portal";
import VehicleCard from "../../../../components/generic/card/VehicleCard";
import datasetParser from "../../../../utils/dataset/datasetParser";
import {useHandleFormParams} from "../hooks/useHandleFormParams.ts";
import {useEffect} from "react";

interface VehicleCardsPortalType {
    type: string,
    typeId: number,
    minNoOfPersons: number,
    maxNoOfPersons: number,
    discountedPrice: string,
    returnPrice: string,
    photoUrl: string,
    info: string[]
}

function VehicleCardsPortal() {
    const {vehicles} = datasetParser({id: "vehicle-group-portal"}) as Record<string, VehicleCardsPortalType>;
    const {
        populateSearchForm
    } = useHandleFormParams();

    useEffect(() => {
        populateSearchForm();
    }, []);

    return (
        <Portal
            domNodeId="vehicle-group-portal"
        >
            {Object.entries(vehicles).map(([key, vehicle]) => {
                return (
                    <VehicleCard
                        key={key}
                        vehicleGroup={vehicle.type}
                        vehicleGroupId={vehicle.typeId}
                        vehicleImagePath={vehicle.photoUrl}
                        vehicleMinPaxCapacity={vehicle.minNoOfPersons}
                        vehicleMaxPaxCapacity={vehicle.maxNoOfPersons}
                        vehicleGroupPerks={vehicle.info}
                        vehiclePrice={vehicle.discountedPrice}
                    />
                )
            })}
        </Portal>
    )
}

export default VehicleCardsPortal;