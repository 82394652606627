import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import PoolMozioSearchResultsResponseType from "../../../../api/types/PoolMozioSearchResultsResponse.type.ts";
import {searchResultsSliceInitialState} from "../constants/searchResultsSliceInitialState.ts";

const searchResultsSlice = createSlice({
    name: "searchResults",
    initialState: searchResultsSliceInitialState,
    reducers: {
        handleMozioSequentialUnsuccessfulFetch: (state, action: PayloadAction<PoolMozioSearchResultsResponseType | "++">) => {
            const getNewCount = {
                // failed ping
                [`${action.payload === "++"}`]: state.unsuccessfulMozioFetchCount + 1,
                // returned []
                [`${action.payload !== "++" && action.payload?.data?.results?.length === 0}`]: state.unsuccessfulMozioFetchCount + 1
            }

            return {
                ...state,
                // if nothing above, everything went gucci, reset counter
                unsuccessfulMozioFetchCount: getNewCount?.true ?? 0,
            }
        },
        resetMozioSequentialUnsuccessfulFetch: (state) => ({
            ...state,
            unsuccessfulMozioFetchCount: 0,
            successfulMozioFetchCount: 0
        }),
        incrementMozioSuccessfulCount: (state) => ({
                ...state,
                successfulMozioFetchCount: state.successfulMozioFetchCount + 1

        }),
        completeMozioSuccessfulCount: (state) => ({
            ...state,
            successfulMozioFetchCount: 10
        }),
        setShouldUseStationParams: (state, action) => ({
            ...state,
            shouldUseStationParams: action.payload
        }),
        setSearchedWithStations: (state, action) => ({
            ...state,
            searchedWithStations: action.payload
        }),
        setSelectedVehicleCategory: (state, action) => ({
            ...state,
            selectedVehicleCategory: action.payload
        }),
        setCategoryPrice: (state, action) => ({
            ...state,
            categoryPrices: action.payload
        }),
        setPreselectCategory: (state, action) => ({
            ...state,
            preselectCategory: action.payload
        })
    }
})

export const {
    handleMozioSequentialUnsuccessfulFetch,
    resetMozioSequentialUnsuccessfulFetch,
    incrementMozioSuccessfulCount,
    completeMozioSuccessfulCount,
    setShouldUseStationParams,
    setSearchedWithStations,
    setSelectedVehicleCategory,
    setCategoryPrice,
    setPreselectCategory
} = searchResultsSlice.actions

export default searchResultsSlice.reducer