import VehicleCards from "./VehicleCards.tsx";
import MozioVehicleCards from "../features/mozioResults/components/MozioVehicleCards.tsx";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import {useEffect, useRef, useState} from "react";
import openDialog from "../../../features/dialog/functions/openDialog.ts";
import PricesMayVaryNotification from "./PricesMayVaryNotification.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {
    maxConsequentFailedMozioFetchCount
} from "../features/mozioResults/constants/maxConsequentFailedMozioFetchCount.ts";
import {cloneDeep} from "lodash";
import VehicleType from "../../../types/VehicleType.type.ts";
import ProgressBar from "../../../components/generic/loaders/ProgressBar.tsx";
import useFormPlaceValidator from "../hooks/useFormPlaceValidator.ts";
import PoolMozioSearchResultsResponseType from "../../../api/types/PoolMozioSearchResultsResponse.type.ts";
import IncompleteSearchFormDataDialog
    from "../../SearchResults/features/incompleteDialog/components/IncompleteSearchFormDataDialog.tsx";
import FilterCategorySection from "../features/filterResults/components/FilterCategorySection.tsx";
import {setCategoryPrice} from "../reducers/functions/searchResultsSlice.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";

interface SearchResultsProps {
    data: any,
    isLoading: boolean,
    error: FetchBaseQueryError | SerializedError | undefined,
    mozioVehiclesData: PoolMozioSearchResultsResponseType
    mozioIsUninitialized: boolean
    mozioIsLoading: boolean
    unsuccessfulMozioFetchCount: number
    originalArgs: any
}

function SearchResults(props: SearchResultsProps) {
    const dispatch = useAppDispatch();
    const {
        data,
        mozioVehiclesData,
        mozioIsUninitialized,
        mozioIsLoading,
        unsuccessfulMozioFetchCount,
        originalArgs
    } = props
    const [mozioVehicles, setMozioVehicles] = useState<VehicleType[]>(cloneDeep(mozioVehiclesData?.data?.results))
    const {
        successfulMozioFetchCount,
        categoryPrices
    } = useAppSelector((state) => (state.searchResults))

    const {lastSearchInvalidDeparture, lastSearchInvalidDestination} = useFormPlaceValidator()
    const isComplete = !(lastSearchInvalidDestination || lastSearchInvalidDeparture)


    const dialogRef = useRef<HTMLDialogElement>(null)
    const onIncompleteSearchFormData = () => {
        openDialog(dialogRef)
    }

    useEffect(() => {
        let currentCategoryPrices = {};
        const vehicles = [...data?.data?.gbt?.vehicleGroups || [], ...mozioVehiclesData?.data?.results || []]
        vehicles.forEach((vehicleType) => {
            if (!currentCategoryPrices[vehicleType.vehicleCategoryId] || Number(currentCategoryPrices[vehicleType.vehicleCategoryId]) > Number(vehicleType.price)) {
                currentCategoryPrices = ({
                    ...currentCategoryPrices,
                    [vehicleType.vehicleCategoryId]: vehicleType.price
                })
                dispatch(setCategoryPrice(currentCategoryPrices));
            }
        });
        if (mozioVehiclesData?.data?.results) {
            const sortedVehicles = cloneDeep(mozioVehiclesData?.data?.results)
            setMozioVehicles(sortedVehicles.sort((a, b) => {
                return a.price - b.price
            }))
        }
    }, [mozioVehiclesData, data])

    return (
        <div className="gb--vehicle__results">
            {((successfulMozioFetchCount < 10 && unsuccessfulMozioFetchCount < maxConsequentFailedMozioFetchCount && mozioVehiclesData?.data?.moreComing) || mozioIsLoading) && (
                <div style={{padding: "0 0 1rem 0"}}>
                    <ProgressBar displaySign percentage={successfulMozioFetchCount} convertToPercentage/>
                </div>

            )}
            <FilterCategorySection/>
            {!isComplete && (
                <PricesMayVaryNotification/>
            )}
            {data?.data?.gbt && (
                <VehicleCards
                    isReturn={data.data.isRoundTrip}
                    isAirport={data?.data?.pickupInfo.isAirport || data?.data?.dropOffInfo.isAirport}
                    prepaymentRate={data?.data?.gbt?.prepaymentRate}
                    duration={data?.data?.duration}
                    vehicles={data?.data?.gbt?.vehicleGroups}
                    paymentMethods={data?.data?.gbt?.paymentMethods}
                    onIncompleteSearchFormData={onIncompleteSearchFormData}
                    categoryPrices={categoryPrices}
                />
            )}
            {!!data && !!data?.data?.mozioSearchId && data?.data?.integrations?.mozio?.moreComing && (
                <MozioVehicleCards
                    isLoading={mozioIsUninitialized || mozioIsLoading}
                    isReturn={data.data.isRoundTrip}
                    paymentMethods={data.data.integrations.mozio.paymentMethods}
                    isAirport={data?.data?.pickupInfo.isAirport || data?.data?.dropOffInfo.isAirport}
                    isFetching={!!mozioVehiclesData?.data?.moreComing && unsuccessfulMozioFetchCount !== maxConsequentFailedMozioFetchCount}
                    vehicles={mozioVehicles}
                    duration={data?.data?.duration}
                    onIncompleteSearchFormData={onIncompleteSearchFormData}
                    categoryPrices={categoryPrices}
                />
            )}
            <IncompleteSearchFormDataDialog
                dialogRef={dialogRef}
                originalArgs={originalArgs}
            />
        </div>
    )
}

export default SearchResults