import CollapsableComponent from "../../../../components/generic/CollapsableComponent.tsx"
import Column from "../../../../features/containers/components/Column.tsx"
import Row from "../../../../features/containers/components/Row.tsx"
import useDisplayDuration from "../../../searchResults/features/tripInfo/hooks/useDisplayDuration.ts";
import handleDisplayDate from "../../functions/handleDisplayDate.ts";

interface BookingTransferInfoProps {
    startDate: string
    pickUpTime?: string
    departure: string
    destination: string
    label: string
    duration: number
}
const BookingTransferInfo = (props: BookingTransferInfoProps) => {
    const {startDate, pickUpTime, departure, destination, label, duration} = props
    const durationLabel = useDisplayDuration(duration)

    const startDateDisplay = handleDisplayDate(startDate, pickUpTime ?? startDate)
    const returnDateDisplay = handleDisplayDate(startDate, pickUpTime ?? startDate, duration)

    return (
        <CollapsableComponent
            label={label}
            collapsable
            wrapper
            hrClassName={'gb--divider__hr'}
        >
            <Column>
                <Row className="gb--booking__info">
                    <Column>
                        <div className="gb--booking__date">{startDateDisplay}</div>
                        <div className="gb--booking__location">{departure}</div>
                    </Column>
                </Row>
                <Row className="gb--booking__info">
                    <div className="gb--booking__direction">
                        <hr className="gb--booking__connection" />
                    </div>
                    <div className="gb--booking__estimate">
                        {durationLabel}
                    </div>
                </Row>
                <Row>
                    <Column>
                        <div className="gb--booking__date">{returnDateDisplay}</div>
                        <div className="gb--booking__location">{destination}</div>
                    </Column>
                </Row>
            </Column>
        </CollapsableComponent>
    )
}

export default BookingTransferInfo
