import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import useNavigateToSearchResults from "../../../routing/navigation/useNavigateToSearchResults.ts";
import {handleBookingDateFormat} from "../../../pages/searchResults/functions/handleBookingDateFormat.ts";
import StationType from "../features/stations/types/Station.type.ts";

export function useNavigateToSearch() {
    const {
        departureDate,
        numberOfPassengers,
    } = useAppSelector((state) => (state?.form))

    const {navigateToSearchResults: navigate} = useNavigateToSearchResults()

    const navigateToSearchResults = (fromStation: StationType, toStation: StationType) => {
        const fromSlug = `${fromStation?.slug}-${fromStation?.id}`
        const toSlug = `${toStation?.slug}-${toStation?.id}`
        const date = handleBookingDateFormat(departureDate, "-")
        const passengers = `${numberOfPassengers}`

        navigate(fromSlug, toSlug, date, passengers)
    }

    return {
        navigateToSearchResults,
    }
}