import Button from "../../../../components/generic/Button.tsx"
import IconArrowBack from "../../../../components/generic/icons/IconArrowBack.tsx"
import {useNavigate} from "react-router"
import LoaderButton from "../../../../components/generic/loaders/LoaderButton.tsx"
import useIsDesktop from "../../../../hooks/selector/useIsDesktop.ts"
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts"

interface BookingNavigationButtonsProps {
    isLoading: boolean
    handleSubmit: (event) => void
}

const BookingNavigationButtons = ({isLoading, handleSubmit}: BookingNavigationButtonsProps) => {
    const navigate = useNavigate()
    const bookButtonLabel = useTranslation("booking.form.button") as string
    const backButtonLabel = useTranslation("booking.form.backToVehicle") as string
    const isSmallDisplay = !useIsDesktop()

    const handleNavigateBack = () => {
        navigate(-1)
    }

    return (
        <div className="gb--booking__footer">
            {!isSmallDisplay && (
                <div>
                    <Button
                        className="gray"
                        additionalClasses="btn--icon gb--booking__button"
                        onClick={handleNavigateBack}
                    >
                        <div className="btn--icon-transparent">
                            <IconArrowBack />
                        </div>
                        {backButtonLabel}
                    </Button>
                </div>
            )}
            <div className="book-button">
                {isLoading ? (
                    <div className="btn btn--orange-dark return-button-submit">
                        <LoaderButton
                            width="18"
                            height="18"
                        />
                    </div>
                ) : (
                    <button
                        className="btn btn--orange-dark return-button-submit"
                        type="button"
                        onClick={handleSubmit}
                    >
                        {bookButtonLabel}
                    </button>
                )}
            </div>
        </div>
    )
}

export default BookingNavigationButtons
