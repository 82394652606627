import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import BookingType from "../types/BookingType.type.ts";
import {ContactFormPayloadType} from "../features/contact/types/ContactFormPayload.type.ts";
import {PaymentFormPayloadType} from "../features/payment/types/PaymentFormPayload.type.ts";
import {PrivacyFormPayloadType} from "../features/privacy/types/PrivacyFormPayload.type.ts";
import {ContactInformationFormFieldType} from "../features/contact/types/ContactInformationFormField.type.ts";
import {bookingSliceInitialState} from "../constants/bookingSliceInitialState.ts";
import {FlightFormPayloadType} from "../features/flight/types/FlightFormPayload.type.ts";

// this is stupid af but TS cant infer the type to a state
const initialState = bookingSliceInitialState as BookingType

const bookingSlice = createSlice({
    name: 'bookingForm',
    initialState,
    reducers: {
        setFlightTransferFormData: (state: BookingType, action: PayloadAction<FlightFormPayloadType>) => {
            state.flightTransferForm[action.payload.type] = {
                ...action.payload,
                errorMessage: state.flightTransferForm[action.payload.type]?.errorMessage,
            }
        },

        setReturnFlightTransferFormData: (state: BookingType, action: PayloadAction<FlightFormPayloadType>) => {
            state.returnFlightTransferForm[action.payload.type] = {
                ...action.payload,
                errorMessage: state.returnFlightTransferForm[action.payload.type]?.errorMessage
            }
        },

        setContactFormData: (state: BookingType, action: PayloadAction<ContactFormPayloadType | ContactInformationFormFieldType>) => {
            state.contactInformationForm[action.payload.type] = {
                ...action.payload,
                errorMessage: state.contactInformationForm[action.payload.type].errorMessage,
            }
        },

        setPaymentTransferFormData: (state: BookingType, action: PayloadAction<PaymentFormPayloadType>) => {
            state.paymentTransferForm[action.payload.type] = {
                ...action.payload,
                errorMessage: state.paymentTransferForm[action.payload.type].errorMessage
            }
        },

        setPrivacyTransferFormData: (state: BookingType, action: PayloadAction<PrivacyFormPayloadType>) => {
            state.privacyTransferForm[action.payload.type] = {
                ...action.payload,
                errorMessage: state.privacyTransferForm[action.payload.type].errorMessage,
            }
        },

        setDisplayFormErrors: (state: BookingType, action: PayloadAction<boolean>) => {
            state.displayFormErrors = action.payload
        },

        setStartLocation: (state, action: PayloadAction<string>) => (
            {...state, startLocation: action.payload}
        ),
        setDestinationLocation: (state, action: PayloadAction<string>) => (
            {...state, destinationLocation: action.payload}
        ),
        setNumberOfPeople: (state, action: PayloadAction<number>) => (
            {...state, numberOfPeople: action.payload}
        ),
        setVehicleGroup: (state, action: PayloadAction<number>) => (
            {...state, vehicleGroup: action.payload}
        ),
        setCountry: (state, action: PayloadAction<string>) => (
            {...state, country: action.payload}
        ),
        setCountryCode: (state, action: PayloadAction<string>) => (
            {...state, countryCode: action.payload}
        ),
        setIsoCode: (state, action: PayloadAction<string>) => ({
            ...state,
            isoCode: action.payload,
        }),
        setReturnTrip: (state, action: PayloadAction<boolean>) => (
            {...state, returnTrip: action.payload}
        ),
        setReturnFlightNumber: (state, action: PayloadAction<string>) => (
            {...state, returnFlightNumber: action.payload}
        ),
        setComment: (state, action: PayloadAction<string>) => (
            {...state, comment: action.payload}
        ),
        setPrivacyPolicy: (state, action: PayloadAction<boolean>) => (
            {...state, privacyPolicy: action.payload}
        ),
        setReviewMailConsent: (state, action: PayloadAction<boolean>) => (
            {...state, reviewMailConsent: action.payload}
        ),
        setNewsletterConsent: (state, action: PayloadAction<boolean>) => (
            {...state, newsletterConsent: action.payload}
        ),
        setLocale: (state, action: PayloadAction<string>) => (
            {...state, locale: action.payload}
        ),
        setToken: (state, action: PayloadAction<string>) => (
            {...state, _token: action.payload}
        ),
        setDataSignature: (state, action: PayloadAction<string>) => (
            {...state, dataSignature: action.payload}
        ),
        setIsDepartureAirport: (state, action: PayloadAction<boolean>) => (
            {...state, isDepartureAirport: action.payload}
        ),
        setIsDestinationAirport: (state, action: PayloadAction<boolean>) => (
            {...state, isDestinationAirport: action.payload}
        ),
        setSubmitTimestamp: (state, action: PayloadAction<string>) => (
            {...state, submitTimestamp: action.payload}
        ),
        setAmenities: (state, action: PayloadAction<boolean>) => (
            {...state, amenities: action.payload}
        ),
        setIsBooked: (state, action) => (
            {...state, isBooked: action.payload}
        ),
        clearData: (state) => (
            {
                ...bookingSliceInitialState,
                dataSignature: state.dataSignature,
                locale: state.locale
            }
        ),
        clear: () => bookingSliceInitialState,
    },
})

export const {
    setComment,
    setCountry,
    setCountryCode,
    setIsoCode,
    setDestinationLocation,
    setLocale,
    setNewsletterConsent,
    setNumberOfPeople,
    setPrivacyPolicy,
    setReturnFlightNumber,
    setReviewMailConsent,
    setReturnTrip,
    setVehicleGroup,
    setStartLocation,
    setToken,
    clearData,
    clear,
    setDataSignature,
    setFlightTransferFormData,
    setReturnFlightTransferFormData,
    setContactFormData,
    setPaymentTransferFormData,
    setPrivacyTransferFormData,
    setDisplayFormErrors,
    setIsDestinationAirport,
    setIsDepartureAirport,
    setSubmitTimestamp,
    setAmenities,
    setIsBooked
} = bookingSlice.actions

export default bookingSlice.reducer