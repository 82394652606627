
interface PillLabelProps {
    label: string
    color?: 'green' | 'red'
}
const PillLabel = (props: PillLabelProps) => {
    const {
        label,
        color = 'green'
    } = props

    return (
        <div className={`gb--pill-label gb--pill-label__${color}`}>
            {/*<div className='gb--pill-label-icon'>*/}
            {/*    <IconCheckmark fill='#7ed321'/>*/}
            {/*</div>*/}
            {label}
        </div>
    );
};

export default PillLabel;