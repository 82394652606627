import Row from "../../../features/containers/components/Row.tsx"
import useIsDesktop from "../../../hooks/selector/useIsDesktop.ts"
import Checkbox from "../../../components/generic/Checkbox.tsx"
import {setNewsletterConsent, setReviewMailConsent} from "../reducers/functions/bookingSlice.ts"
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts"
import Column from "../../../features/containers/components/Column.tsx"
import useBookingInfoInput from "../features/bookingInfoInputField/hooks/useBookingInfoInput.ts"
import useTranslation from "../../../features/translation/hooks/useTranslation.ts"
import TermsAndConditionsCheckbox
    from "../../../features/inputs/features/termsAndConditionsCheckbox/components/TermsAndConditionsCheckbox.tsx"
import {useSubmitBooking} from "../hooks/useSubmitBooking.ts"
import ReCAPTCHA from "react-google-recaptcha"
import {useState} from "react"
import Modal from "../../../components/generic/modal/Modal.tsx"
import IconTimes from "../../../components/generic/icons/IconTimes.tsx"
import ToggleSwitch from "../../../components/generic/ToggleSwitch.tsx"
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts"
import BookingTransferPreview from "../features/bookingTransferPreview/BookingTransferPreview.tsx"
import BookingContactInformation from "../features/bookingContactInformation/BookingContactInformation.tsx"
import BookingPaymentSelect from "../features/bookingPaymentSelect/BookingPaymentSelect.tsx"
import BookingPriceInfo from "../features/bookingPriceInfo/BookingPriceInfo.tsx"
import BookingNavigationButtons from "../features/bookingNavigationButtons/BookingNavigationButtons.tsx"
import BookingStripe from "../features/bookingStripe/BookingStripe.tsx"
import BookingFlightNumber from "../features/bookingFlightNumber/components/BookingFlightNumber.tsx";
import BookingAdditionalNoteSection from "../features/bookinAdditionalNotes/BookingAdditionalNoteSection.tsx";
import BookingAmenities from "../features/BookingAmenities/components/BookingAmenities.tsx";
import BookingMobileTransferDetails from "../features/bookingTransferPreview/Mobile/BookingMobileTransferDetails.tsx";
import {PaymentMethods} from "../../../constants/PaymentMethods.ts";

interface BookingFormProps {
    data: any
    isLoading: boolean
}

const BookingForm = ({data, isLoading}: BookingFormProps) => {
    const dispatch = useAppDispatch()
    const review = useTranslation("booking.form.review") as string
    const newsletter = useTranslation("booking.form.newsletter") as string
    const {clientSecret} = useAppSelector((state) => (state?.stripe))
    const [loading, setLoading] = useState(false)

    const {
        newsletterConsent,
        privacyTransferForm,
        reviewMailConsent,
        paymentTransferForm
    } = useAppSelector((state) => state?.bookingForm)
    const {isIntegration, amenities} = useAppSelector((state) => state?.vehicleType)

    const {
        handleSubmit,
        recaptchaRef,
        errorMessage: bookingErrorMessage,
        displayError: bookingDisplayError,
        clearError: clearBookingError,
    } = useSubmitBooking(setLoading, data, isLoading)

    const isDesktop = useIsDesktop()
    const paymentMethod = paymentTransferForm.paymentMethod.value

    const {value, errorMessage, displayError, updateStore} = useBookingInfoInput({
        formType: "privacyTransferForm",
        field: "privacyPolicy",
        fieldValidationMethod: () => !privacyTransferForm.privacyPolicy.value,
    })
    const errorMessageString = useTranslation(errorMessage) as string

    const toggleTermsAndConditions = () => {
        updateStore(!value, !value)
    }

    return (
        <>
            <div className={'gb--booking-background'}>
                <Row
                    justify
                    className="info-cards"
                >
                    <div className="gb--card gb--booking__wrapper gb--booking__preview">
                        {isDesktop
                            ? <BookingTransferPreview data={data}/>
                            : <BookingMobileTransferDetails returnTrip={data.data.isRoundTrip}/>
                        }
                    </div>
                    <div className="gb--booking__fields">
                        <div className="gb--card gb--booking__wrapper">
                            <BookingContactInformation/>
                            {!isDesktop && (
                                <div className="gb--booking__container">
                                    <ToggleSwitch
                                        id="reviewConsent"
                                        label={review}
                                        minimize
                                        onChange={() => {
                                            dispatch(setReviewMailConsent(!reviewMailConsent))
                                        }}
                                        isChecked={reviewMailConsent}
                                    />
                                </div>
                            )}
                            {(data?.data?.pickupInfo.isAirport || data?.data?.dropOffInfo.isAirport) && (
                                <BookingFlightNumber data={data.data}/>
                            )}
                            {amenities.length !== 0 && (
                                <BookingAmenities
                                    amenities={amenities}
                                    isIntegration={isIntegration}
                                    isReturn={data.data.isRoundTrip}
                                />
                            )}
                            <BookingAdditionalNoteSection/>
                            {!isIntegration && (
                                <BookingPaymentSelect/>
                            )}
                            <BookingPriceInfo isReturn={data.data.isRoundTrip}/>
                        </div>
                        <Column className="gb--booking__consent">
                            <Checkbox
                                className="md-checkbox"
                                isChecked={newsletterConsent}
                                onChange={() => {
                                    dispatch(setNewsletterConsent(!newsletterConsent))
                                }}
                            >
                                {newsletter}
                            </Checkbox>
                            <TermsAndConditionsCheckbox
                                onChange={toggleTermsAndConditions}
                                isChecked={privacyTransferForm.privacyPolicy.value}
                                displayError={displayError}
                                errorMessageString={errorMessageString}
                                errorStyle={{margin: "1rem 4rem 1rem 0"}}
                            />
                        </Column>
                    </div>
                </Row>
                <ReCAPTCHA
                    sitekey={window.googleInvisibleCaptchaSiteKey}
                    ref={recaptchaRef}
                    size="invisible"
                    hidden
                />
                <BookingNavigationButtons
                    handleSubmit={handleSubmit}
                    isLoading={loading}
                />
            </div>
            {clientSecret && (paymentMethod === PaymentMethods.PREPAYMENT || paymentMethod === PaymentMethods.CARD) && (
                <BookingStripe
                    setLoading={setLoading}
                    clientSecret={clientSecret}
                />
            )}
            {bookingDisplayError && (
                <Modal
                    message={""}
                    title={bookingErrorMessage}
                    closeAction={clearBookingError}
                    closeIcon={<IconTimes/>}
                />
            )}
        </>
    )
}

export default BookingForm
