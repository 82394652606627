import useTranslation from "../../../features/translation/hooks/useTranslation.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";

const SuccessfulBookingButton = () => {
    const buttonLabel = useTranslation('booking.confirmation.backToHome') as string;
    const {
        siteUrl,
        locale
    } = useAppSelector((state) => state?.page)

    return (
        <div className='gb--success-booking__button'>
            <a className="btn btn--orange-dark return-button" href={`//${siteUrl}/${locale}`}>
                {buttonLabel}
            </a>
        </div>
    );
};

export default SuccessfulBookingButton;