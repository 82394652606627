import Navbar from "./Navbar";
import Logo from "./Logo";
import SearchForm from "../../../../form/components/SearchForm.tsx";
import HamburgerBar from "./HamburgerBar.tsx";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import MobileOverlayContainer from "../../../../../features/mobileOverlays/components/MobileOverlayContainer.tsx";
import {useMatches} from "react-router";
import {handleIsBooking} from "../../../../bookingNavigation/functions/handleIsBooking.ts";
import useIsDesktop from "../../../../../hooks/selector/useIsDesktop.ts";

function TopBar() {
    const matches = useMatches();
    const isBooking = handleIsBooking(matches);
    const isSmallDisplay = !useIsDesktop();

    const {
        mobileOverlayActive,
    } = useAppSelector((state) => (state.mobile))

    if (mobileOverlayActive) {
        return (
            <MobileOverlayContainer/>
        )
    }

    if (!(isBooking && isSmallDisplay)) {
        return (
            <div className="gb--navigation">
                <header className="gb--header gb--row gb--row-middle gb--row-justify">
                    <Logo/>
                    {!isSmallDisplay ? (
                        <Navbar/>
                    ) : (
                        <HamburgerBar/>
                    )}
                </header>
                <SearchForm/>
            </div>
        )
    }

    return (
        <></>
    )
}

{/*<SearchForm/>*/
}

export default TopBar;