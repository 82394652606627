export const routeIds = {
    homepage: 'homepage',
    staticPages: 'staticPages',
    stationToStation: 'stationToStation',
    booking: 'booking',
    vehicle: 'vehicle',
    bookingInfo: 'booking-info',
    successful: 'successful',
    contact: 'contact',
    faq: 'faq',
    transfer: 'transfer',
    privacyPolicy: 'privacyPolicy',
    termsAndConditions: 'termsAndConditions'
}