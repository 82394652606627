import BookingTransferInfo from "./BookingTransferInfo.tsx"
import {useAppSelector} from "../../../../hooks/selector/useAppSelector.ts"
import BookingVehicleTypePreview from "./BookingVehicleTypePreview.tsx"
import BookingTransferPreviewPrice from "./BookingTransferPreviewPrice.tsx"
import {setReviewMailConsent} from "../../reducers/functions/bookingSlice.ts"
import {useAppDispatch} from "../../../../hooks/dispatch/useAppDispatch.ts"
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts"
import ToggleSwitch from "../../../../components/generic/ToggleSwitch.tsx"
import {useEffect, useState} from "react";

interface BookingTransferPreviewProps {
    data: any;
}

const BookingTransferPreview = (props: BookingTransferPreviewProps) => {
    const dispatch = useAppDispatch()
    // TODO find vehicle depending on the ID and is it integration or not
    const vehicleType = useAppSelector((state) => state?.vehicleType)
    const {
        reviewMailConsent,
    } = useAppSelector((state) => state?.bookingForm)
    const {
        fromPlace,
        toPlace
    } = useAppSelector((state) => state?.form)
    const {
        data,
    } = props

    const transferInformation = useTranslation("booking.form.transferInfo") as string
    const returnTransferInformation = useTranslation("booking.form.returnTransferInfo") as string
    const review = useTranslation("booking.form.review") as string
    const [fromLabel, setFromLabel] = useState();
    const [toLabel, setToLabel] = useState();

    useEffect(() => {
        setFromLabel(!!fromPlace.placeId ? fromPlace.placeName : data?.data.pickupInfo.pickUpPlace)
        setToLabel(!!toPlace.placeId ? toPlace.placeName : data?.data.dropOffInfo.dropOffPlace)
    }, [])

    return (
        <>
            <BookingVehicleTypePreview
                vehicleType={vehicleType}
                returnTrip={data?.data.isRoundTrip}
            />
            <BookingTransferInfo
                startDate={data?.data.pickupInfo.pickUpDateTime}
                pickUpTime={data?.data.pickupInfo.pickUpDateTime}
                departure={fromLabel}
                label={transferInformation}
                destination={toLabel}
                duration={data?.data.duration}
            />
            {data?.data.isRoundTrip && (
                <BookingTransferInfo
                    startDate={data?.data.returnPickUpDateTime}
                    pickUpTime={data?.data.returnPickUpDateTime}
                    departure={toLabel}
                    label={returnTransferInformation}
                    destination={fromLabel}
                    duration={data?.data.duration}
                />
            )}
            <div className="gb--preview__container">
                <ToggleSwitch
                    id="reviewConsent"
                    isChecked={reviewMailConsent}
                    onChange={() => {
                        dispatch(setReviewMailConsent(!reviewMailConsent))
                    }}
                    label={review}
                    minimize
                />
            </div>
            <BookingTransferPreviewPrice
                vehicleType={vehicleType}
                returnTrip={data?.data.isRoundTrip}
            />
        </>
    )
}

export default BookingTransferPreview
