import {ChangeEvent, useState} from 'react';
import IconAirplane from "../../../../../../../components/generic/icons/bookingIcons/IconAirplane.tsx";
import {useFlightNumber} from "../../../hooks/useFlightNumber.ts";
import CustomDropdown from "../../../../../../../components/generic/CustomDropdown.tsx";
import FlightNumberList from "../../../components/FlightNumberList.tsx";
import {useToggle} from "../../../../../../../hooks/dispatch/useToggle.ts";
import useBookingInfoInput from "../../../../bookingInfoInputField/hooks/useBookingInfoInput.ts";
import useTranslation from "../../../../../../../features/translation/hooks/useTranslation.ts";
import {useAppSelector} from "../../../../../../../hooks/selector/useAppSelector.ts";
import FloatingLabelInput from "../../../../../../../components/generic/formElements/FloatingLabelInput.tsx";
import {FlightType} from "../../../../../reducers/features/flight/types/Flight.type.ts";
import ScrollToElement from "../../../../../../../features/scroll/components/ScrollToElement.tsx";

interface BookingFlightNumber {
    data: any
}


import {Notification} from "@getbybus/gbb-green";

const FlightNumber = (props: BookingFlightNumber) => {
    //TODO insert translation
    const flightNumber = useTranslation("booking.form.flightNumber") as string
    const {response, getFlights} = useFlightNumber()
    const {value: isDropdownOpen, set: setIsDropdownOpen} = useToggle()
    const {
        flightTransferForm,
        submitTimestamp
    } = useAppSelector((state) => state?.bookingForm)
    const [flightNumberPlaceholder, setFlightNumberPlaceholder] = useState<string>(
        flightTransferForm?.flightNumber?.value?.flightNumber || "",
    )
    const {
        data
    } = props

    const {displayError, errorMessage, updateStore, clearStore} = useBookingInfoInput({
        formType: "flightTransferForm",
        field: "flightNumber",
        fieldValidationMethod: () => flightTransferForm.flightNumber.value.flightNumber.length > 0,
    })
    const errorMessageString = useTranslation(errorMessage) as string

    const handleChange = (event: ChangeEvent) => {
        setIsDropdownOpen(true)
        setFlightNumberPlaceholder(event.target.value)
        getFlights(event.target.value, data)
        if (!event.target.value.length) {
            clearStore()
        }
    }

    const handleSelect = (option: FlightType) => {
        updateStore(option, true)
        setIsDropdownOpen(false)
        setFlightNumberPlaceholder(option.flightInfo)
    }


    return (
        <>
            <CustomDropdown
                field={
                    <FloatingLabelInput
                        value={flightNumberPlaceholder}
                        onChange={handleChange}
                        background={'white'}
                        id={"flight-number"}
                        label={flightNumber}
                        icon={<IconAirplane/>}
                        type={"text"}
                        autoComplete={'off'}
                    />
                }
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
            >
                <FlightNumberList handleSelectOption={handleSelect} isLoading={response.isLoading} options={response.data} isError={response.isError}/>
            </CustomDropdown>
            {displayError && !response?.isError && (
                <ScrollToElement condition={submitTimestamp}>
                    <Notification
                        isLarge
                        notificationType="red"
                    >
                        {" "}
                        {errorMessageString}{" "}
                    </Notification>
                </ScrollToElement>
            )}
            {response?.isError && (
                <Notification
                    isLarge
                    notificationType="red"
                >
                    {" "}
                    {response.error.data.data.message}{" "}
                </Notification>
            )}
        </>


    );
};

export default FlightNumber;