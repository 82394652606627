import {useEffect} from "react"
import BookingForm from "./BookingForm.tsx"
import {validateSearchResultsErrorType} from "../../../api/functions/validateSearchResultsErrorType.ts";
import Modal from "../../../components/generic/modal/Modal.tsx";
import {Button, Row} from "@getbybus/gbb-green";
import Translation from "../../../features/translation/components/Translation.tsx";
import useTranslation from "../../../features/translation/hooks/useTranslation.ts";
import useNavigateToSearch from "../hooks/useNavigateToSearch.ts";
import useLocale from "../../../hooks/selector/useLocale.ts";
import useStopAllExistingBookings from "../hooks/useStopAllExistingBookings.ts";
import BookingFormLoader from "./BookingFormLoader.tsx";
import {useLocation} from "react-router";
import useCachedData from "../../searchResults/hooks/useCachedData.ts";

function BookingFormWrapper() {
    const locale = useLocale()
    const genericError = useTranslation("base.validation.somethingWentWrong") as string
    const {
        fetchedGbtData: data,
        fetchedGbtIsLoading: isLoading,
        fetchedGbtIsError: isError,
        fetchedGbtError: error,
    } = useCachedData(false)

    const {navigateToSearchResults} = useNavigateToSearch()

    useStopAllExistingBookings()

    // On complete, if something is missing return to search results (upon refresh / landing)
    useEffect(() => {
        if (!data && !isLoading){
            navigateToSearchResults()
        }

        if (error && validateSearchResultsErrorType(error)) {
            if (!data || error){
                navigateToSearchResults()
            }
        }
    }, [isLoading, data])

    if (isError && validateSearchResultsErrorType(error)) {
        if (!error?.data?.shouldRedirectToContactPage) {
            const message = error.data.message || genericError
            return (
                // TODO Remove this modal, and render normal component so the user can use the search form (maybe some image of no results :)
                <Modal
                    title={message}
                    message={(
                        <Row style={{justifyContent: "center"}}>
                            <Button
                                onClick={() => (window.location.href = `//${window.location.host}/${locale}`)}
                            >
                                <Translation translationKey={"booking.confirmation.backToHome"}/>
                            </Button>
                        </Row>
                    )}
                />
            )
        } else if (error?.data?.shouldRedirectToContactPage) {
            // TODO this should not be the way to handle this, but for now it is :?
            return <></>
        }
    } else if (!isLoading && data) {
        return <BookingForm isLoading={isLoading} data={data}/>
    } else {
        return (
            <BookingFormLoader/>
        )
    }
}

export default BookingFormWrapper
