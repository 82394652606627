import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import useGetFirstBatchOfMozioData from "./useGetFirstBatchOfMozioData.ts";
import useGetMoreOfMozioData from "./useGetMoreOfMozioData.ts";
import useCachedMozioData from "./useCachedMozioData.ts";
import {InitialSearchRequestType} from "../../../../../api/types/InitialSearchRequest.type.ts";

export default function useGetMozioSearchResults(gbtDataIsLoading: boolean, gbtDataIsError: FetchBaseQueryError | SerializedError | undefined, gbtVehiclesData: any, originalArgs: InitialSearchRequestType) {
    const {
        fetchedMozioData,
        fetchedMozioIsLoading,
        fetchedMozioIsUninitialized,
    } = useCachedMozioData(gbtVehiclesData?.data?.mozioSearchId);

    const {
        data,
        isLoading,
        isUninitialized,
    } = useGetFirstBatchOfMozioData(gbtDataIsLoading, gbtDataIsError, gbtVehiclesData, !fetchedMozioIsUninitialized, originalArgs)

    useGetMoreOfMozioData(!!fetchedMozioData ? data : fetchedMozioData, gbtVehiclesData, originalArgs);

    if (!!fetchedMozioData) {
        return {
            mozioVehiclesData: fetchedMozioData,
            mozioIsLoading: fetchedMozioIsLoading,
            mozioIsUninitialized: fetchedMozioIsUninitialized,
        }
    }

    return {
        mozioVehiclesData: data,
        mozioIsLoading: isLoading,
        mozioIsUninitialized: isUninitialized,
    }
}