import {PlaceType} from "../../../../../types/Place.type.ts";
import {setFromPlace, setToPlace} from "../../../../../reducers/formSlice.ts";
import {
    dismissAllOverlays,
    setDeparturePlaceInput,
    setDestinationPlaceInput
} from "../../../../../reducers/mobileSlice.ts";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import useIsOverlaySubtreeActive from "../../../../../features/mobileOverlays/hooks/useIsOverlaySubtreeActive.ts";

interface UseHandleUserPlaceSelectParams {
    name: "departure" | "destination",
    response: any,
    setIsDropdownOpen: (value: boolean) => void,
    setPlacePlaceholder: (value: string) => void,
}

export default function useHandleUserPlaceSelect(params: UseHandleUserPlaceSelectParams) {
    const {
        name,
        response,
        setIsDropdownOpen,
        setPlacePlaceholder,
    } = params

    const dispatch = useAppDispatch()
    const isOverlaySubtreeActive = useIsOverlaySubtreeActive()
    const handleSelect = (option: PlaceType) => {
        setIsDropdownOpen(false)

        if (response?.data?.status !== "error") {
            setPlacePlaceholder(option.placeName)
            name === 'departure'
                ? dispatch(setFromPlace(option))
                : dispatch(setToPlace(option))
        }
        if (isOverlaySubtreeActive) {
            name === "departure"
                ? dispatch(setDeparturePlaceInput(false))
                : dispatch(setDestinationPlaceInput(false))
        } else {
            dispatch(dismissAllOverlays());
        }
    }

    return {
        handleSelect,
    }
}