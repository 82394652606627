import {
    initialState,
    setDuration,
    setIsIntegration,
    setPaymentMethods,
    setPrepaymentRate,
    setVehicleType
} from "../../reducers/vehicleTypeSlice.ts";
import {
    setAmenities,
    setFlightTransferFormData,
    setPaymentTransferFormData,
    setReturnFlightTransferFormData,
    setVehicleGroup
} from "../../pages/bookingForm/reducers/functions/bookingSlice.ts";
import {setBookingNavigatePath, toggleIsCookieModalOpen} from "../../reducers/pageSlice.ts";
import {useAppSelector} from "../../hooks/selector/useAppSelector.ts";
import {getTranslatedRoute} from "../functions/getTranslatedRoute.ts";
import {generatePath} from "react-router-dom";
import {useAppDispatch} from "../../hooks/dispatch/useAppDispatch.ts";
import VehicleType from "../../types/VehicleType.type.ts";
import {toLower} from "lodash";
import useLocale from "../../hooks/selector/useLocale.ts";
import {LocaleType} from "../../types/Locale.type.ts";
import useGoTo from "../hooks/useGoTo.ts";
import {NavigateOptions} from "react-router";

// This function allows us to go to booking form from pages other than search results
// It is currently dumb, as we only go to booking from search results, but might be used for static pages later
export default function useNavigateToBookingForm() {
    const locale = toLower(useLocale()) as LocaleType;
    const {goTo} = useGoTo()

    const dispatch = useAppDispatch();
    const {cookiesConsent} = useAppSelector((state) => (state.page))

    const {getPath} = getTranslatedRoute();
    const translatedBooking = getPath("booking");

    const navigateToBookingForm = (fromSlug: string, toSlug: string, date: string, passengers: string, vehicleType: VehicleType, duration: number, prepaymentRate: number, paymentMethods: any, isIntegration: boolean, isAirport: boolean, options?: NavigateOptions) => {
        const path = generatePath(`/:locale/${translatedBooking}/:fromSlug/:toSlug/:date/:passengers/${vehicleType.id}`,
            {
                locale,
                fromSlug,
                toSlug,
                date,
                passengers,
            })
        dispatch(setVehicleType(initialState))
        dispatch(setVehicleType(vehicleType));
        dispatch(setDuration(duration))
        dispatch(setVehicleGroup(vehicleType.id));
        dispatch(setPrepaymentRate(prepaymentRate));
        dispatch(setPaymentMethods(paymentMethods))
        if (isIntegration) {
            dispatch(setIsIntegration(isIntegration))
            dispatch(setPaymentTransferFormData({
                type: "paymentMethod",
                value: 3,
                isValid: true,
                displayError: false,
            }))
        }
        if (vehicleType.amenities?.length) {
            const initialAmenities = {}
            vehicleType.amenities.forEach((amenity) => {
                initialAmenities[amenity.id] = {
                    count: 0,
                    maxQuantity: amenity.maxQuantity,
                    returnCount: 0
                }
            })

            dispatch(setAmenities(initialAmenities))
        }
        if (!isAirport) {
            dispatch(setFlightTransferFormData({
                type: "flightNumber",
                value: '',
                isValid: true,
                displayError: false
            }))

            dispatch(setReturnFlightTransferFormData({
                type: "returnFlightNumber",
                value: '',
                isValid: true,
                displayError: false
            }))
        }
        if (!cookiesConsent) {
            dispatch(toggleIsCookieModalOpen(true))
            dispatch(setBookingNavigatePath(path))
        } else {
            goTo(path, options)
        }
    }

    return {
        navigateToBookingForm,
    }
}