import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";
import {useEffect, useRef, useState} from "react";
import useCachedData from "../../../hooks/useCachedData.ts";
import useCachedMozioData from "../../mozioResults/hooks/useCachedMozioData.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {setSelectedVehicleCategory} from "../../../reducers/functions/searchResultsSlice.ts";

export const useFilterCategory = () => {
    const dispatch = useAppDispatch();
    const MAX_CATEGORIES = 4;
    const MIN_CATEGORIES = 0;
    const isMobile = useIsMobile();
    const containerRef = useRef<HTMLDivElement>(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const {
        fetchedGbtData,
    } = useCachedData();

    const {
        fetchedMozioData,
        fetchedMozioIsLoading,
        fetchedMozioIsUninitialized,
    } = useCachedMozioData(fetchedGbtData?.data?.mozioSearchId);

    const {
        categoryPrices,
        preselectCategory,
    } = useAppSelector((state) => state?.searchResults);

    const isLoading = (
            (fetchedGbtData?.data?.integrations.mozio.moreComing && fetchedMozioIsUninitialized)
            ||(fetchedMozioIsLoading && !fetchedMozioIsUninitialized && Object.keys(categoryPrices).length < MAX_CATEGORIES)
            || (!fetchedMozioIsLoading && fetchedMozioData?.data?.moreComing)
        )
        && Object.keys(categoryPrices).length < MAX_CATEGORIES;


    // Commented out since decision was to deploy is without the preselect
    // useEffect(() => {
    //     if (!!Object.keys(categoryPrices)[0]) {
    //         if (preselectCategory) {
    //             dispatch(setSelectedVehicleCategory(Number(Object.keys(categoryPrices)[0])));
    //         }
    //     }
    // }, [categoryPrices]);

    const handleScroll = (offset) => {
        containerRef.current.scrollLeft = containerRef.current.scrollLeft + offset;
    };


    // useEffect on load that sets the event listener so we can determine and set the scroll position
    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            const handleScrollUseEffect = () => {
                setScrollPosition(container.scrollLeft)
            };

            // Add the scroll event listener to the container element
            container.addEventListener('scroll', handleScrollUseEffect);
        }
    }, []);

    return {
        isMobile,
        handleScroll,
        isLoading,
        scrollPosition,
        containerRef,
        categoryPrices,
        fetchedGbtData
    }
}