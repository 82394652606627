import VehicleCards from "../../../components/VehicleCards.tsx";
import VehicleType from "../../../../../types/VehicleType.type.ts";
import VehicleTypeCardLoader from "../../../../../components/generic/loaders/VehicleTypeCardLoader.tsx";

interface MozioVehicleCardsProps {
    isAirport: boolean,
    isLoading: boolean,
    isFetching: boolean,
    isReturn: boolean,
    vehicles: VehicleType[] | undefined,
    duration: number,
    onIncompleteSearchFormData: () => void,
    paymentMethods: Record<number, string>
    categoryPrices: Record<number, string>
}

function MozioVehicleCards(props: MozioVehicleCardsProps) {
    const {
        vehicles,
        isLoading,
        isFetching,
        isAirport,
        isReturn,
        duration,
        onIncompleteSearchFormData,
        paymentMethods,
        categoryPrices
    } = props

    if (isLoading) {
        return (
            <VehicleTypeCardLoader/>
        )
    }

    // TODO check prepayment rate
    return (
        <>
            {isFetching && (
                <VehicleTypeCardLoader/>
            )}
            <VehicleCards
                isReturn={isReturn}
                isAirport={isAirport}
                isIntegration
                vehicles={vehicles}
                duration={duration}
                prepaymentRate={0}
                paymentMethods={paymentMethods}
                onIncompleteSearchFormData={onIncompleteSearchFormData}
                categoryPrices={categoryPrices}
            />

        </>

    )
}

export default MozioVehicleCards