import useFormDataFromLastSearch from "../../../hooks/useFormDataFromLastSearch.ts";
import {useParams} from "react-router";
import {SearchParamsType} from "../../../../../types/SearchParams.type.ts";
import getCityFromSlug from "../../../functions/getCityFromSlug.ts";
import {getDateRequestFormat} from "../../../functions/getDateRequestFormat.ts";
import handleDisplayDate from "../../../../bookingForm/functions/handleDisplayDate.ts";
import useIsPageRerenderOrRedirect
    from "../../../../../features/mobileOverlays/features/incompleteSearchFormDataOverlay/hooks/useIsPageRerenderOrRedirect.ts";

export default function useSearchResultsTripInfo(duration: number) {
    const {
        departureDate,
        toPlace,
        fromPlace,
    } = useFormDataFromLastSearch()

    const {
        fromSlug,
        toSlug,
        date: pickUpDateFromSlug,
    } = useParams<SearchParamsType>()

    const isPageRerenderOrRedirect = useIsPageRerenderOrRedirect()

    const departure = fromPlace?.placeName ?? getCityFromSlug(fromSlug!, "display")
    const destination = toPlace?.placeName ?? getCityFromSlug(toSlug!, "display")

    const date = !!departureDate
        ? departureDate
        : getDateRequestFormat(pickUpDateFromSlug ?? "")


    const startDateDisplay = isPageRerenderOrRedirect ? handleDisplayDate(date!) : handleDisplayDate(date!, date!)
    const returnDateDisplay = isPageRerenderOrRedirect ? handleDisplayDate(date!) : handleDisplayDate(date!, date!, duration)

    return {
        departure,
        destination,
        isPageRerenderOrRedirect,
        startDateDisplay,
        returnDateDisplay,
    }
}