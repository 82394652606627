import IconSuitcaseV2 from "../icons/IconSuitcaseV2"
import Column from "../../../features/containers/components/Column"
import {Link} from "react-router-dom"
import Translation from "../../../features/translation/components/Translation"
import {getTranslatedRoute} from "../../../routing/functions/getTranslatedRoute"
import useLocale from "../../../hooks/selector/useLocale"
import Row from "../../../features/containers/components/Row"
import useIsDesktop from "../../../hooks/selector/useIsDesktop"
import IconPax from "../icons/IconPax"
import datasetParser from "../../../utils/dataset/datasetParser"
import VehicleCardPerks from "./VehicleCardPerks.tsx";
import {useToggle} from "@getbybus/gbb-green";
import {toInteger} from "lodash";

interface VehicleCardProps {
    vehicleGroup?: string
    vehicleGroupId?: number
    vehicleImagePath?: string
    vehicleMinPaxCapacity?: number
    vehicleMaxPaxCapacity?: number
    vehicleGroupPerks?: string[]
    vehiclePrice?: string
}

const VehicleCard = (props: VehicleCardProps) => {
    const {
        vehicleGroup,
        vehicleImagePath,
        vehicleMinPaxCapacity,
        vehicleMaxPaxCapacity,
        vehicleGroupPerks,
        vehiclePrice,
    } = props

    const [integerPart, decimalPart] = vehiclePrice.split('.');

    const {
        searchUrl: {
            fromId,
            fromSlug,
            toId,
            toSlug,
            pickUpDate,
            // noOfPersons
        },
    } = datasetParser({id: "vehicle-group-portal"})

    const isDesktop = useIsDesktop()
    const locale = useLocale()
    const {getPath} = getTranslatedRoute()
    const bookingSlugTranslation = getPath("booking")
    const pickUpDateArray = pickUpDate.split("-")
    const navigateToBooking = `/${locale}/${bookingSlugTranslation}/${fromSlug}-${fromId}/${toSlug}-${toId}/${toInteger(pickUpDateArray[0]) + 1}-${toInteger(pickUpDateArray[1])}-${pickUpDateArray[2]}/${vehicleMinPaxCapacity}`

    const {value, set} = useToggle()

    const handleMouseOver = () => {
        set(true)
    }

    const handleMouseOut = () => {
        set(false)
    }

    return (
        <Link
            to={navigateToBooking}
            className="card__vehicle--sts"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            reloadDocument
        >
            <Row
                justify
                center
                className="gb--card card__vehicle card__vehicle-red"
            >
                <Row
                    wrap
                    className="vehicle-group__desc"
                >
                    <img
                        width="auto"
                        height="auto"
                        src={vehicleImagePath}
                        alt={vehicleGroup}
                    />
                    <Column className="card__content">
                        <p className="gb--emphasize card__title">{vehicleGroup}</p>
                        <Row wrap>
                            <span>
                                <IconPax />
                                {vehicleMinPaxCapacity} - {vehicleMaxPaxCapacity}
                                &nbsp; <Translation translationKey="search.form.passengers" /> &nbsp;
                            </span>
                            <span>
                                <IconSuitcaseV2 />
                                {vehicleMaxPaxCapacity}
                                &nbsp; <Translation translationKey="booking.chooseVehicle.suitcases" />
                            </span>
                        </Row>
                        {isDesktop && value && vehicleGroupPerks?.length &&
                            <Row className="vehicle-group__perks">
                                <VehicleCardPerks vehicleGroupPerks={vehicleGroupPerks}/>
                            </Row>
                        }
                    </Column>
                </Row>
                <Column className="card__action">
                    <Column alignEnd>
                        <span className="vehicle-group__price">
                            {integerPart}
                            {decimalPart && <sup>,{decimalPart}</sup>} &#8364;
                        </span>
                        <small>
                            <Translation translationKey="booking.chooseVehicle.price" />
                        </small>
                    </Column>
                    <button
                        type="button"
                        className={`btn btn--orange-dark`}
                    >
                        <Translation translationKey="booking.form.button" />
                    </button>
                </Column>
            </Row>
        </Link>
    )
}

export default VehicleCard
