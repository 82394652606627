import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {useMemo} from "react";

/**
 * Due to the effect from React/src/layout/header/hooks/useInitializeDataFromParams.ts
 * which initializes data on page rerender before this hook is ran, this has data even on page refresh and redirects
 *
 * this is still useful as not to change displayed data until new search is ran through (the button on search form is clicked)
 */

export default function useFormDataFromLastSearch() {
    const {
        fromPlace,
        toPlace,
        departureDate,
        numberOfPassengers,
        successfulSubmitTimestamp,
    } = useAppSelector((state) => (state?.form))

    return useMemo(() => ({
        fromPlace,
        toPlace,
        departureDate,
        numberOfPassengers,
    }), [successfulSubmitTimestamp])
}