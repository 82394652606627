import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import {setBookingId, setBookingVerificationKey, setClientSecret} from "../../reducers/stripeSlice.ts"
import {
    setSearchFinishedTimestamp,
} from "../../reducers/formSlice.ts"
import SearchResultsResponseType from "../types/SearchResultsResponse.type.ts"
import SubmitContactFormResponseType from "../types/SubmitContactFormResponse.type.ts"
import {BookingSubmitType} from "../types/BookingSubmitType.type.ts";
import {GetPlacesRequestType} from "../types/GetPlacesRequest.type.ts";
import {GetPlacesResponseType} from "../types/GetPlacesResponse.type.ts";
import {PoolMozioSearchResultsRequestType} from "../types/PoolMozioSearchResultsRequest.type.ts";
import {InitialSearchRequestType} from "../types/InitialSearchRequest.type.ts";
import PoolMozioSearchResultsResponseType from "../types/PoolMozioSearchResultsResponse.type.ts";
import getMozioResultsHandler from "../functions/getMozioResultsHandler.ts";
import {
    completeMozioSuccessfulCount,
    handleMozioSequentialUnsuccessfulFetch,
    incrementMozioSuccessfulCount,
    resetMozioSequentialUnsuccessfulFetch,
    setSelectedVehicleCategory
} from "../../pages/searchResults/reducers/functions/searchResultsSlice.ts";
import {LogErrorType} from "../../features/errors/types/LogErrorType.ts";

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: `https://${window.location.host}/api/v1/`,
    }),
    tagTypes: ["Places", "InitialSearch", "Mozio"],
    endpoints: (builder) => ({
        // TODO add the response type once it is defined
        initialSearch: builder.query<SearchResultsResponseType, InitialSearchRequestType>({
            query: (arg) => ({
                url: 'search',
                method: "GET",
                params: arg
            }),
            async onQueryStarted(_arg, {queryFulfilled, dispatch}) {
                try {
                    await queryFulfilled;
                    // }
                } catch (error) {
                    // handle something
                }
                dispatch(setSearchFinishedTimestamp(Date.now()))
                dispatch(resetMozioSequentialUnsuccessfulFetch())
            },
            keepUnusedDataFor: 900,

            providesTags: ["InitialSearch"],
            invalidatesTags: ["Mozio"]
        }),
        // TODO add the response type once it is defined
        pollMozioResults: builder.query<PoolMozioSearchResultsResponseType, PoolMozioSearchResultsRequestType>({
            query: (arg) => ({
                url: 'poll-mozio-search-results',
                method: "GET",
                params: arg
            }),
            providesTags: ["Mozio"],
            keepUnusedDataFor: 900,
        }),
        pollMoreMozioResults: builder.mutation<PoolMozioSearchResultsResponseType, PoolMozioSearchResultsRequestType>({
            query: (arg) => ({
                url: 'poll-mozio-search-results',
                method: "GET",
                params: arg,
            }),
            async onQueryStarted(request, {dispatch, queryFulfilled}) {
                try {
                    const response = await queryFulfilled
                    const {handleNewBatchOfMozioResults} = getMozioResultsHandler(response.data)
                    if (!response.data.data.moreComing) {
                        dispatch(completeMozioSuccessfulCount())
                    }
                    dispatch(handleMozioSequentialUnsuccessfulFetch(response?.data))
                    dispatch(incrementMozioSuccessfulCount())

                    dispatch(apiSlice.util?.updateQueryData(
                        'pollMozioResults',
                        {searchId: request.searchId, locale: request.locale},
                        handleNewBatchOfMozioResults,
                    ))
                } catch (error) {
                    dispatch(handleMozioSequentialUnsuccessfulFetch("++"))
                }
            }
        }),
        bookingSubmit: builder.query({
            query: (arg: BookingSubmitType) => ({
                url: "book",
                method: "POST",
                body: arg,
            }),

            async onQueryStarted(arg, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    dispatch(setBookingVerificationKey(response.data.data.bookingVerificationKey))
                    dispatch(setBookingId(response.data.data.bookingId))
                    dispatch(setClientSecret(response.data.data.clientSecret))
                } catch (error) {
                    // TODO for future reference... add some logger here please
                }
            },
        }),
        bookingIntegrationSubmit: builder.query({
            query: (arg: BookingSubmitType) => ({
                url: "book-integration",
                method: "POST",
                body: arg,
            }),

            async onQueryStarted(arg, {queryFulfilled, dispatch}) {
                try {
                    const response = await queryFulfilled
                    dispatch(setBookingVerificationKey(response.data.data.bookingVerificationKey))
                    dispatch(setBookingId(response.data.data.bookingId))
                    dispatch(setClientSecret(response.data.data.clientSecret))
                } catch (error) {
                    // TODO for future reference... add some logger here please
                }
            },
        }),
        getStripePublishableKey: builder.query({
            query: () => ({
                url: "stripe-publishable-key",
                method: "GET",
            }),
        }),
        verifyBooking: builder.query({
            query: (arg) => ({
                url: "verify-booking",
                method: "POST",
                body: arg,
            }),
        }),
        getPlaces: builder.query<GetPlacesResponseType, GetPlacesRequestType>({
            query: (arg) => ({
                url: "/pickup-places",
                method: "GET",
                params: arg,
            }),
            providesTags: ["Places"],
        }),
        submitContactForm: builder.query<SubmitContactFormResponseType, any>({
            query: (arg) => ({
                url: "contact",
                method: "POST",
                body: arg,
            }),
        }),
        getFlightNumber: builder.query({
            query: (arg) => ({
                url: "get-flight-info",
                method: "GET",
                params: arg
            })
        }),
        logError: builder.query<any, LogErrorType>({
            query: (arg) => ({
                url: 'log-error',
                method: "POST",
                body: arg,
            })
        })
    }),
})

export const {
    useLazyInitialSearchQuery,
    useLazyPollMozioResultsQuery,
    usePollMoreMozioResultsMutation,
    useLazyBookingSubmitQuery,
    useLazyVerifyBookingQuery,
    useGetStripePublishableKeyQuery,
    useLazyGetPlacesQuery,
    useLazySubmitContactFormQuery,
    useLazyGetFlightNumberQuery,
    useLazyBookingIntegrationSubmitQuery,
    useLazyLogErrorQuery,
} = apiSlice
