import Translation from "../../../../../features/translation/components/Translation.tsx";

interface IncompleteDialogHeaderProps {
    isDateOpen: boolean
    isIntegration: boolean
}

const IncompleteDialogHeader = (props: IncompleteDialogHeaderProps) => {
    const {
        isDateOpen,
        isIntegration
    } = props;

    const inventory = isIntegration ? 'integration' : 'native'

    return (
        <>
            {!isDateOpen ? (
                <div>
                    <h2>
                        <Translation
                            translationKey={`booking.search.incompleteAddress.dialog.${inventory}.title`}
                        />
                    </h2>
                    <p>
                        <Translation
                            translationKey={`booking.search.incompleteAddress.dialog.${inventory}.description`}
                        />
                    </p>
                </div>
            ) : (
                <div>
                    <h2>
                        <Translation
                            translationKey="booking.search.incompleteDateAndTime.dialog.title"
                        />
                    </h2>
                    <p>
                        <Translation
                            translationKey="booking.search.incompleteDateAndTime.dialog.description"
                        />
                    </p>
                </div>
            )}
        </>
    );
};

export default IncompleteDialogHeader;