import VehicleCard from "../features/vehicleCard/components/VehicleCard.tsx";
import VehicleType from "../../../types/VehicleType.type.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {setCategoryPrice} from "../reducers/functions/searchResultsSlice.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {ca} from "date-fns/locale";
import {useEffect} from "react";

interface VehicleTypeListProps {
    vehicles?: VehicleType[];
    duration: number;
    prepaymentRate: number;
    paymentMethods: Record<1 | 2 | 3, string> | object;
    isIntegration?: boolean;
    isAirport: boolean;
    isReturn: boolean;
    onIncompleteSearchFormData: () => void;
    categoryPrices: Record<number, string>
}

const VehicleCards = (props: VehicleTypeListProps) => {
    const {
        vehicles = [],
        duration,
        prepaymentRate,
        paymentMethods,
        isIntegration = false,
        isAirport,
        isReturn,
        onIncompleteSearchFormData,
        categoryPrices
    } = props;

    const {
        selectedVehicleCategory,
    } = useAppSelector((state) => state?.searchResults)
    const dispatch = useAppDispatch();

    useEffect(() => {
        vehicles.forEach((vehicleType) => {
            if (!categoryPrices[vehicleType.vehicleCategoryId] || Number(categoryPrices[vehicleType.vehicleCategoryId]) > Number(vehicleType.price)) {
                dispatch(setCategoryPrice({
                    ...categoryPrices,
                    [vehicleType.vehicleCategoryId]: vehicleType.price
                }))
            }
        })
    }, [vehicles]);

    return (
        <div>
            <div>
                {vehicles.map((vehicleType) => {
                    // if (!categoryPrices[vehicleType.vehicleCategoryId] || Number(categoryPrices[vehicleType.vehicleCategoryId]) > Number(vehicleType.price)) {
                    //     dispatch(setCategoryPrice({
                    //         ...categoryPrices,
                    //         [vehicleType.vehicleCategoryId]: vehicleType.price
                    //     }))
                    // }
                    if (!selectedVehicleCategory) {
                        return (
                            <VehicleCard
                                isReturn={isReturn}
                                isAirport={isAirport}
                                isIntegration={isIntegration}
                                prepaymentRate={prepaymentRate}
                                duration={duration}
                                vehicleType={vehicleType}
                                key={vehicleType?.id}
                                paymentMethods={paymentMethods}
                                onIncompleteSearchFormData={onIncompleteSearchFormData}
                                categoryPrices={categoryPrices}
                            />
                        )
                    } else if (selectedVehicleCategory && selectedVehicleCategory === vehicleType.vehicleCategoryId) {
                        return (
                            <VehicleCard
                                isReturn={isReturn}
                                isAirport={isAirport}
                                isIntegration={isIntegration}
                                prepaymentRate={prepaymentRate}
                                duration={duration}
                                vehicleType={vehicleType}
                                key={vehicleType?.id}
                                paymentMethods={paymentMethods}
                                onIncompleteSearchFormData={onIncompleteSearchFormData}
                            />
                        )
                    }
                })}
            </div>
        </div>
    )

};

export default VehicleCards;