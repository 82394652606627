import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {ContactInformationFormType} from "../reducers/features/contact/types/ContactInformationForm.type.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {setDisplayFormErrors, setSubmitTimestamp} from "../reducers/functions/bookingSlice.ts";
import {PaymentTransferFormType} from "../reducers/features/payment/types/PaymentTransferForm.type.ts";
import {PrivacyTransferFormType} from "../reducers/features/privacy/types/PrivacyTransferForm.type.ts";
import {FlightTransferFormType} from "../reducers/features/flight/types/FlightTransferForm.type.ts";

export function useHandleBookingInfo () {
    const dispatch = useAppDispatch();
    const {
        contactInformationForm,
        paymentTransferForm,
        privacyTransferForm,
        flightTransferForm,
        returnFlightTransferForm
    } = useAppSelector((state) => state?.bookingForm);


    const validateFormData = (formData: ContactInformationFormType|FlightTransferFormType|PaymentTransferFormType|PrivacyTransferFormType) => {
        return Object.values(formData).every((inputField) => inputField.isValid)
    }

    const contactFormFieldsValid = () => (
        validateFormData(contactInformationForm)
    )

    const paymentFormFieldsValid = () => (
        validateFormData(paymentTransferForm)
    )

    const privacyFormFieldsValid = () => (
        validateFormData(privacyTransferForm)
    )

    const flightFormFieldsValid = () => (
        validateFormData(flightTransferForm)
    )

    const returnFlightFormFieldsValid = (isRoundTrip: boolean) => (
        isRoundTrip ? validateFormData(returnFlightTransferForm) : true
    )

    const validateAllFields = (isRoundTrip: boolean) => {
        const allFieldsValid = contactFormFieldsValid() && paymentFormFieldsValid() && privacyFormFieldsValid() && flightFormFieldsValid() && returnFlightFormFieldsValid(isRoundTrip)
        dispatch((setDisplayFormErrors(!allFieldsValid)));
        dispatch(setSubmitTimestamp(new Date().toISOString()))

        return allFieldsValid
    }


    return {
        validateAllFields,
    }
}


