import PlaceSelectors from "../../../../../layout/form/features/stations/components/PlaceSelectors.tsx";
import DateTimeField from "../../../../../layout/form/features/dates/components/DateTimeField.tsx";
import {Button, useToggle} from "@getbybus/gbb-green";
import Dialog from "../../../../../features/dialog/components/Dialog.tsx";
import useConfirmUpdatedFormData
    from "../../../../../features/mobileOverlays/features/incompleteSearchFormDataOverlay/hooks/useConfirmUpdatedFormData.ts";
import {RefObject, } from "react";
import Translation from "../../../../../features/translation/components/Translation.tsx";
import FullScreenLoader from "../../../../../components/generic/loaders/FullScreenLoader.tsx";
import useIsPageRerenderOrRedirect
    from "../../../../../features/mobileOverlays/features/incompleteSearchFormDataOverlay/hooks/useIsPageRerenderOrRedirect.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import DateTimeIncomplete from "./DateTimeIncomplete.tsx";
import IncompleteDialogHeader from "./IncompleteDialogHeader.tsx";
import useFormPlaceValidator from "../../../../searchResults/hooks/useFormPlaceValidator.ts";
import useCachedData from "../../../../searchResults/hooks/useCachedData.ts";

interface IncompleteSearchFormDataDialogProps {
    dialogRef: RefObject<HTMLDialogElement>
    originalArgs: any
}

function IncompleteSearchFormDataDialog({dialogRef, originalArgs}: IncompleteSearchFormDataDialogProps) {
    const {
        confirmNewData,
        isLoading,
    } = useConfirmUpdatedFormData(() => {
        dialogRef.current?.close()
    }, originalArgs)
    const {invalidDeparture, invalidDestination} = useFormPlaceValidator()
    const isPageRerenderOrRedirect = useIsPageRerenderOrRedirect()
    const {value, onChange, set} = useToggle()
    const {
        fetchedGbtData: data
    } = useCachedData(isPageRerenderOrRedirect)

    const {
        returnDate,
        departureDate
    } = useAppSelector((state) => state?.form);
    const {
        isIntegration
    } = useAppSelector((state) => state?.vehicleType);

    return (
        <Dialog
            dialogRef={dialogRef}
            background="gray"
            close={() => {
                dialogRef.current?.close()
                set(false)
            }}
        >
            {!isLoading ? (
                <>
                    <IncompleteDialogHeader isIntegration={isIntegration} isDateOpen={value}/>
                    {!value && (
                        <>
                            <PlaceSelectors
                                showIsValid
                                searchStrict
                                data={data}
                            />
                        </>
                    )}
                    <div className="search__select search__select--datepicker gb--row gb--row-middle">
                        {value ? (
                            <DateTimeIncomplete maxDateTime={returnDate} closeClick={onChange}/>
                        ) : (
                            <DateTimeField isValid={!!departureDate} showIsValid={true} additionalOnClick={onChange} maxDateTime={returnDate}/>
                        )}
                    </div>
                    {!value && (
                        <Button
                            onClick={confirmNewData}
                            type="button"
                            width="full"
                            size="large"
                            variant="orangeDark"
                            appearance={(invalidDeparture || invalidDestination || !departureDate) ? "inactive" : undefined}
                            additionalClasses="incomplete-search-form-overlay--content__button-confirm"
                        >
                            <Translation
                                translationKey="booking.search.incompleteAddress.confirm"
                            />
                        </Button>
                    )}
                </>
            ) : (
                <>
                    <FullScreenLoader/>
                    Please wait a moment longer as we check for latest transfer offers.
                </>
            )}
        </Dialog>
    )
}

export default IncompleteSearchFormDataDialog