import useTranslation from "../../../features/translation/hooks/useTranslation.ts";
import {useMatches} from "react-router";
import format from "date-fns/format";
import BookingNavigationItem from "./BookingNavigationItem.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import BookingNavigationResultsInfo from "./BookingNavigationResultsInfo.tsx";
import useNavigateToHome from "../../../routing/navigation/useNavigateToHome.ts";


const BookingNavigationItemList = () => {
    const matches = useMatches();
    const selectVehicle = useTranslation('booking.chooseVehicle.heading') as string;
    const bookingConfirmation = useTranslation('booking.confirmation.confirmation') as string;
    const transferAndPayment = useTranslation('booking.form.heading') as string;
    const {navigateToHome} = useNavigateToHome()
    const {
        departureDate,
        numberOfPassengers
    } = useAppSelector((state) => state?.form);

    // Todo replace with the data from the root once the app is localised
    const BookingSteps = {
        'vehicle': {
            order: 1,
            text: selectVehicle,
            subtitle: departureDate ? `${format(new Date(departureDate), 'hh:mm aa | EEE MMM dd')} | ${numberOfPassengers} pax` : "",
            children: <BookingNavigationResultsInfo/>,
            goBack: () => navigateToHome()
        },
        'booking-info': {
            order: 2,
            text: transferAndPayment,
            subtitle: departureDate ? format(new Date(departureDate), 'hh:mm aa | EEE MMM dd') : "",
            children: null,
            goBack: undefined
        },
        'successful': {
            order: 3,
            text: bookingConfirmation,
            subtitle: departureDate ? format(new Date(departureDate), 'hh:mm aa | EEE MMM dd') : "",
            children: null,
            goBack: undefined
        }
    };

    return (
        <div className='gb--booking-navigation__container'>
            {Object.entries(BookingSteps).map(([step, {text, subtitle, order, children, goBack}]) => (
                <BookingNavigationItem
                    id={step}
                    title={text}
                    subtitle={subtitle}
                    matches={matches}
                    key={step}
                    order={order}
                    children={children}
                    goBack={goBack}
                />
            ))}
        </div>
    );
};

export default BookingNavigationItemList;