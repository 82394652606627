import Card from "../../../../../components/generic/Card.tsx";
import VehicleCardVehicleInfo from "./VehicleCardVehicleInfo.tsx";
import VehicleCardPaymentInfo from "./VehicleCardPaymentInfo.tsx";
import Row from "../../../../../features/containers/components/Row.tsx";
import VehicleType from "../../../../../types/VehicleType.type.ts";
import useFormPlaceValidator from "../../../hooks/useFormPlaceValidator.ts";
import useVehicleSelected from "../hooks/useVehicleSelected.ts";

interface VehicleTypeCardProps {
    vehicleType: VehicleType;
    duration: number;
    prepaymentRate: number;
    paymentMethods: Record<1 | 2 | 3, string> | object;
    isIntegration: boolean;
    isAirport: boolean;
    isReturn: boolean;
    onIncompleteSearchFormData: () => void
}

const VehicleCard = (props: VehicleTypeCardProps) => {
    const {
        vehicleType,
        duration,
        prepaymentRate,
        paymentMethods,
        isIntegration,
        isAirport,
        isReturn,
        onIncompleteSearchFormData,
    } = props;

    const {lastSearchInvalidDestination, lastSearchInvalidDeparture} = useFormPlaceValidator()
    const isComplete = !((lastSearchInvalidDestination || lastSearchInvalidDeparture) && isIntegration)
    const additionalClass = 'gb--vehicle gb--card-enlarge__small';
    const {vehicleSelected} = useVehicleSelected({
        vehicleType,
        duration,
        prepaymentRate,
        paymentMethods,
        isIntegration,
        isAirport,
        onIncompleteSearchFormData,
    });

    return (
        <div className='gb--row gb--row-justify' onClick={vehicleSelected} key={vehicleType.id}>
            <div className='gb--column gb--column-center card-width'>
                <Card additionalClass={additionalClass}>
                    {
                        <Row justify center className='gb--vehicle__content'>
                            <VehicleCardVehicleInfo vehicleType={vehicleType}/>
                            <VehicleCardPaymentInfo
                                isReturn={isReturn}
                                isComplete={isComplete}
                                prepaymentRate={prepaymentRate}
                                duration={duration}
                                vehicleType={vehicleType}
                            />
                        </Row>
                    }
                </Card>
            </div>
        </div>
    )
};

export default VehicleCard;