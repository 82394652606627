
interface RadioFieldProps {
    label: string | React.ReactNode;
    labelClassName?: string;
    value: string | number;
    required: boolean;
    passengerId: number;
    disabled?: boolean;
    selected: boolean;
    onClick: (e: React.MouseEvent<HTMLLabelElement>) => void,
}

function RadioField(props: RadioFieldProps) {
    const {
        label,
        value,
        onClick,
    } = props;

    return (
        <label
            className="gb-checkmark-container"
            onClick={onClick}
            key={value}
        >
            <h5>{label}</h5>
            <input type="radio" name="radio" value={value}/>
            <span className="gb-checkmark"></span>
        </label>
    )


}

export default RadioField;