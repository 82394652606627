import {ChangeEvent, FocusEvent, ReactNode} from 'react';

interface FloatingLabelInputProps {
    icon: ReactNode,
    label: string,
    id: string,
    type: 'text' | 'number' | 'email' | 'phone',
    value?: string,
    inputRef?: any,
    onClick?: any,
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
    readOnly?: boolean,
    onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    maxLength?: number,
    autoComplete?: string,
    background: 'white' | 'blue'
}

const FloatingLabelInput = (props: FloatingLabelInputProps) => {
    const {
        icon,
        id,
        label,
        type,
        value,
        inputRef,
        onClick,
        onChange,
        readOnly,
        onBlur,
        background,
        ...rest
    } = props;

    return (
        <div className={`gb--labelInput ${background !== 'blue' ? `gb--labelInput__${background}` : ''}`} ref={inputRef}>
            {icon}
            <input
                readOnly={readOnly}
                onBlur={onBlur}
                onFocus={onClick}
                value={value}
                ref={inputRef}
                id={id}
                type={type}
                name={id}
                placeholder=" "
                onClick={onClick}
                onChange={onChange}
                {...rest}
            />
            <label className="gb--emphasize">{label}</label>
        </div>
    );
};

export default FloatingLabelInput;