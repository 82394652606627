import Modal from "../../../../components/generic/modal/Modal.tsx"
import useIsMobile from "../../../../hooks/selector/useIsMobile.ts";
import BookingStripeBody from "./bookingStripePaymentInfo/BookingStripeBody.tsx";
import {useEffect} from "react";
import {useAppDispatch} from "../../../../hooks/dispatch/useAppDispatch.ts";

interface BookingStripeProps {
    clientSecret: string
    setLoading: (flag: boolean) => void
}

const BookingStripe = (props: BookingStripeProps) => {
    const {
        setLoading
    } = props

    useEffect(() => {
        setLoading(false)
        // if (isMobile) {
        //     dispatch(toggleStripeMobileOverlay())
        // }
    }, []);

    // if (!isMobile) {
        return (
                <Modal
                    className="modal-dialog gb-modal-dialog gb--stripe__modal"
                    message={
                        <BookingStripeBody/>
                    }
                />
        )
    // }
}

export default BookingStripe
