import {ReactNode, useEffect} from "react"
import MobileAppTitle from "../components/MobileAppTitle"
import useScrollableModal from "../../../hooks/effects/useScrollableModal.ts"
import ScrollToTop from "../../scroll/components/ScrollToTop.tsx"
import {clearFormErrors} from "../../../reducers/formSlice.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import MobileOverlayContentWrapper from "./MobileOverlayContentWrapper.tsx";
import useScrollToTopOnSafari from "../../../hooks/effects/useScrollToTopOnSafari.ts";

interface MobileOverlayProps {
    backClick?: () => void
    title: string | ReactNode,
    subtitle?: string
    children: ReactNode
    wrapperClassName?: string,
    className?: string
    slideInDirection?: "up" | "down" | "left" | "right",
    isActionSheet?: boolean,
}

const MobileOverlay = (props: MobileOverlayProps) => {
    const {
        title,
        subtitle,
        backClick,
        children,
        className,
        wrapperClassName = "",
        slideInDirection = "up",
        isActionSheet = false
    } = props
    // useBrowserHistoryBackInterceptor()
    const dispatch = useAppDispatch()
    useScrollableModal()
    useScrollToTopOnSafari()

    useEffect(() => {
        dispatch(clearFormErrors())
    }, []);

    return (
            <MobileOverlayContentWrapper
                isActionSheet={isActionSheet}
                slideInDirection={slideInDirection}
                wrapperClassName={wrapperClassName}
            >
                <ScrollToTop/>
                <MobileAppTitle
                    backClick={backClick}
                    className={className}
                    title={title}
                    subtitle={subtitle}
                    fitContent={isActionSheet}
                />
                {children}
            </MobileOverlayContentWrapper>

    )
}
export default MobileOverlay
