import {
    maxConsequentFailedMozioFetchCount
} from "../features/mozioResults/constants/maxConsequentFailedMozioFetchCount.ts";

export default function noSearchResults(data: any, mozioVehiclesData: any, mozioIsUninitialized: boolean, mozioIsLoading: boolean, unsuccessfulMozioFetchCount: number) {
    return (
        // no GBT data (our partners)
        (!data?.data?.gbt
        // is not currently loading any more Mozio data
        && !(!mozioIsUninitialized || mozioIsLoading)
        // no more data will be fetched from mozio
        && !mozioVehiclesData?.data?.moreComing
        // max consequent failed mozio fetch count is not reached
        && unsuccessfulMozioFetchCount !== maxConsequentFailedMozioFetchCount
        // no mozio results or mozio returned empty array
        && (!mozioVehiclesData?.data?.results || mozioVehiclesData?.data?.results.length === 0)
        // no mozio results initially
        && !data?.data?.integrations.mozio.moreComing)
        // No results after loading for some time / or no more coming + no results
        || (
            ((unsuccessfulMozioFetchCount === maxConsequentFailedMozioFetchCount) || !mozioVehiclesData?.data?.moreComing)
            && mozioVehiclesData?.data?.results.length === 0
            && !data?.data?.gbt
            && (!mozioIsLoading || unsuccessfulMozioFetchCount === maxConsequentFailedMozioFetchCount)
        )
    )
}