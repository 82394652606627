import {toLower} from "lodash";
import useLocale from "../../../hooks/selector/useLocale.ts";
import {LocaleType} from "../../../types/Locale.type.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {apiSlice} from "../../../api/reducers/apiSlice.ts";
import {useMemo} from "react";
import getCityFromSlug from "../functions/getCityFromSlug.ts";
import {useParams} from "react-router";
import {SearchParamsType} from "../../../types/SearchParams.type.ts";
import {getStationIdFromParam} from "../functions/getStationIdFromParam.ts";
import {getDateRequestFormat} from "../functions/getDateRequestFormat.ts";

export default function useCachedData(getLocationsFromParams: boolean | undefined = false) {
    const locale = toLower(useLocale()) as LocaleType;

    const {
        fromPlace,
        toPlace,
        departureDate: pickUpDateTime,
        returnDate: returnPickUpDateTime,
        numberOfPassengers: numberOfPeople,
        successfulSubmitTimestamp,
        searchFinishedTimestamp,
        fromStation,
        toStation
    } = useAppSelector((state) => (state?.form));

    const {
        shouldUseStationParams,
        searchedWithStations
    } = useAppSelector((state) => state?.searchResults)

    const {
        fromSlug,
        toSlug,
        date
    } = useParams<SearchParamsType>()

    let searchData;

    if (shouldUseStationParams) {
        searchData = apiSlice.endpoints?.initialSearch.useQueryState({
            startLocation: getCityFromSlug(fromSlug as string),
            destinationLocation: getCityFromSlug(toSlug as string),
            startStationId: getStationIdFromParam(fromSlug as string),
            destinationStationId: getStationIdFromParam(toSlug as string),
            locale,
            pickUpDateTime: !!pickUpDateTime ? pickUpDateTime : new Date(new Date(getDateRequestFormat(date) as string).setHours(23, 59, 59, 999)).toISOString(),
            returnPickUpDateTime: returnPickUpDateTime ?? "",
            numberOfPeople
        });
    } else {
        const fromStationId = searchedWithStations ? fromStation?.id ?? Number(getStationIdFromParam(fromSlug as string)) : '';
        const toStationId = searchedWithStations ? toStation?.id ?? Number(getStationIdFromParam(toSlug as string)) : '';

        searchData = apiSlice.endpoints?.initialSearch.useQueryState({
            startLocation: fromPlace?.placeName ?? "",
            destinationLocation: toPlace?.placeName ?? "",
            locale,
            pickUpDateTime: !!pickUpDateTime ? pickUpDateTime : new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
            startStationId: fromStationId,
            destinationStationId: toStationId,
            returnPickUpDateTime: returnPickUpDateTime ?? "",
            numberOfPeople
        })
    }

    const {
        data,
        error,
        isError,
        isLoading,
        isFetching,
        isUninitialized,
        status,
        originalArgs
    } = useMemo(() => (searchData), [successfulSubmitTimestamp, searchFinishedTimestamp])


    return {
        fetchedGbtData: data,
        fetchedGbtError: error,
        fetchedGbtIsError: isError,
        fetchedGbtIsLoading: isFetching || isLoading,
        fetchedGbtIsUninitialized: isUninitialized,
        fetchedGbtStatus: status,
        fetchedGbtOriginalArgs: originalArgs
    }
}