import datasetParser from "../../../../utils/dataset/datasetParser.ts"
import {useAppDispatch} from "../../../../hooks/dispatch/useAppDispatch.ts"
import {setDepartureDate, setFromStation, setToStation} from "../../../../reducers/formSlice.ts"
import {addDays} from "date-fns";

export const useHandleFormParams = () => {
    const dispatch = useAppDispatch()

    const getCountryShortCode = (label: string) => {
        const shortCodeArray = label.split(" ")
        const shortCode = shortCodeArray[shortCodeArray.length - 1]

        return shortCode.replace(/[()]/g, "")
    }

    const populateSearchForm = () => {
        const {
            searchUrl: {from, fromId, fromSlug, to, toId, toSlug, pickUpDate},
        } = datasetParser({id: "vehicle-group-portal"})

        const pickUpDateArray = pickUpDate.split("-")
        const pickUpDateIso = addDays(new Date(pickUpDateArray[2], pickUpDateArray[1] - 1, pickUpDateArray[0], 12), 1).toISOString()

        dispatch(setDepartureDate(pickUpDateIso))
        dispatch(setFromStation({id: fromId, label: from, slug: fromSlug, countryShortCode: getCountryShortCode(from)}))
        dispatch(setToStation({id: toId, label: to, slug: toSlug, countryShortCode: getCountryShortCode(to)}))
    }

    return {
        populateSearchForm,
    }
}
