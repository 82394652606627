import VehicleCardsPortal from "./components/VehicleCardsPortal";

const StationToStationPage = () => (
    <>
        {window.vehicles && (
            <VehicleCardsPortal />
        )}
    </>
)

export default StationToStationPage;