import {ChangeEvent} from "react"
import {ContactInformationFormType} from "../../../reducers/features/contact/types/ContactInformationForm.type.ts"
import {TransferFormType} from "../../../reducers/features/transfer/types/TransferForm.type.ts"
import {BookingInfoInputType} from "../types/BookingInfoInput.type.ts"
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts"
import {
    setContactFormData,
    setPaymentTransferFormData,
    setPrivacyTransferFormData,
    setReturnFlightTransferFormData,
    setFlightTransferFormData,
} from "../../../reducers/functions/bookingSlice.ts"
import {PaymentTransferFormType} from "../../../reducers/features/payment/types/PaymentTransferForm.type.ts"
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts"
import {PrivacyTransferFormType} from "../../../reducers/features/privacy/types/PrivacyTransferForm.type.ts"
import {LocationType} from "../../../reducers/features/transfer/types/Location.type.ts";
import {isBoolean, isNumber, isString} from "lodash";
import {BookingInfoInputFieldsType} from "../types/BookingInfoInputFields.type.ts";
import {
    FlightTransferFormType,
    ReturnFlightTransferFormType
} from "../../../reducers/features/flight/types/FlightTransferForm.type.ts";
import {FlightType} from "../../../reducers/features/flight/types/Flight.type.ts";

type BasicFieldType = {
    type: BookingInfoInputFieldsType
    value: any,
    isValid: boolean,
    displayError: boolean,
    errorMessage: string
}
export default function useBookingInfoInput({formType, field, fieldValidationMethod}: BookingInfoInputType) {
    const dispatch = useAppDispatch()
    const bookingForm = useAppSelector((state) => state.bookingForm)

    const setErrorMessage = (message: string) => {
        if (formType === "contactInformationForm") {
            dispatch(setContactFormData({
                ...getFieldValue(),
                type: <keyof ContactInformationFormType>field,
            }))
        }

        if (formType === "flightTransferForm") {
            dispatch(setFlightTransferFormData({
                ...getFieldValue(),
                type: <keyof TransferFormType>field,
            }))
        }

        if (formType === "returnFlightTransferForm") {
            dispatch(setReturnFlightTransferFormData({
                ...getFieldValue(),
                type: <keyof TransferFormType>field,
            }))
        }

        if (formType === "paymentTransferForm") {
            dispatch(setPaymentTransferFormData({
                ...getFieldValue(),
                type: <keyof PaymentTransferFormType>field,
            }))
        }

        if (formType === "privacyTransferForm") {
            dispatch(setPrivacyTransferFormData({
                ...getFieldValue(),
                type: <keyof PrivacyTransferFormType>field,
            }))
        }
    }

    const validateField = (value: string) => fieldValidationMethod(value)

    const clearStore = () => {
        updateStore("", false, true)
    }

    const updateStore = (value: string | LocationType | boolean | number | FlightType, isValid: boolean, displayError: boolean = false) => {
        if (formType === "contactInformationForm" && isString(value)) {
            dispatch(setContactFormData({
                ...getFieldValue(),
                type: <keyof ContactInformationFormType>field,
                value,
                isValid,
                displayError,
            }))
        }

        if (formType === "flightTransferForm") {
            dispatch(setFlightTransferFormData({
                ...getFieldValue(),
                type: <keyof FlightTransferFormType>field,
                value,
                isValid,
                displayError,
            }))
        }

        if (formType === "returnFlightTransferForm") {
            dispatch(setReturnFlightTransferFormData({
                ...getFieldValue(),
                type: <keyof FlightTransferFormType>field,
                value,
                isValid,
                displayError,
            }))
        }

        if (formType === "paymentTransferForm" && isNumber(value)) {
            dispatch(setPaymentTransferFormData({
                ...getFieldValue(),
                type: <keyof PaymentTransferFormType>field,
                value,
                isValid,
                displayError,
            }))
        }

        if (formType === "privacyTransferForm" && isBoolean(value)) {
            dispatch(setPrivacyTransferFormData({
                ...getFieldValue(),
                type: <keyof PrivacyTransferFormType>field,
                value,
                isValid,
                displayError,
            }))
        }
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const isValid = validateField(event.target.value)
        if (isValid) {
            clearError()
        }

        updateStore(event.target.value, isValid, isValid)
    }

    const setDisplayError = (showError: boolean) => {
        if (formType === "contactInformationForm") {
            dispatch(setContactFormData({
                ...getFieldValue(),
                type: <keyof ContactInformationFormType>field,
                displayError: showError,
            }))
        }

        if (formType === "flightTransferForm") {
            dispatch(setFlightTransferFormData({
                ...getFieldValue(),
                type: <keyof TransferFormType>field,
                displayError: showError,
            }))
        }

        if (formType === "returnFlightTransferForm") {
            dispatch(setReturnFlightTransferFormData({
                ...getFieldValue(),
                type: <keyof TransferFormType>field,
                displayError: showError,
            }))
        }

        if (formType === "paymentTransferForm") {
            dispatch(setPaymentTransferFormData({
                ...getFieldValue(),
                type: <keyof PaymentTransferFormType>field,
                displayError: showError,
            }))
        }

        if (formType === "privacyTransferForm") {
            dispatch(setPrivacyTransferFormData({
                ...getFieldValue(),
                type: <keyof PrivacyTransferFormType>field,
                displayError: showError,
            }))
        }
    }

    const clearError = () => {
        setErrorMessage(getFieldValue().errorMessage)
        setDisplayError(false)
    }

    // @ts-ignore
    const getFieldValue = (): BasicFieldType => {
        if (formType === "contactInformationForm") {
            return bookingForm.contactInformationForm[<keyof ContactInformationFormType>field]
        }
        if (formType === "flightTransferForm") {
            return bookingForm.flightTransferForm[<keyof FlightTransferFormType>field]
        }
        if (formType === "returnFlightTransferForm") {
            return bookingForm.returnFlightTransferForm[<keyof ReturnFlightTransferFormType>field]
        }
        if (formType === "paymentTransferForm") {
            return bookingForm.paymentTransferForm[<keyof PaymentTransferFormType>field]
        }
        if (formType === "privacyTransferForm") {
            return bookingForm.privacyTransferForm[<keyof PrivacyTransferFormType>field]
        }
    }

    const onBlur = () => {
        const field = getFieldValue()
        if (field && field.isValid) {
            setDisplayError(!field.isValid)
        }
    }

    return {
        value: getFieldValue()?.value,
        setDisplayError,
        errorMessage: getFieldValue().errorMessage,
        // Display on blur or when global state is set (such as onSubmit)
        displayError: (getFieldValue()?.displayError || bookingForm.displayFormErrors) && !getFieldValue()?.isValid,
        setErrorMessage,
        clearError,
        onBlur,
        onChange,
        updateStore,
        clearStore,
    }
}
