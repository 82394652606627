import Card from "../Card.tsx";
import Row from "../../../features/containers/components/Row.tsx";
import Column from "../../../features/containers/components/Column.tsx";
import LinePlaceholder from "../../../features/placeholders/components/LinePlaceholder.tsx";
import Image from "../../../features/images/components/Image.tsx";

interface FilterCategoryLoaderProps {
    single?: boolean
}

const FilterCategoryLoader = ({single}: FilterCategoryLoaderProps) => {
    return (
        <Row className={'gb--category-wrapper'}>
            {Array.from({length: single ? 1 : 4}).map((_, index) => (
                <div key={index} className={'gb--category'}>
                    <Card additionalClass={'gb--category-card gb--card-enlarge'}>
                        <Column>
                            <Row className={'gb--category-card-info__loader'} wrap gap>
                                <Image
                                    width="75"
                                    height="40"
                                    imageFileName="car_loader"
                                    imageFileFormat="png"
                                />
                                <Column style={{width: '100%'}}>
                                    <LinePlaceholder height={'24px'}/>
                                    <LinePlaceholder height={'24px'}/>
                                </Column>
                            </Row>
                            <span className={`gb--category-card-capacity`}>
                                    <LinePlaceholder height={'24px'}/>
                                </span>
                        </Column>
                    </Card>
                </div>
            ))}
        </Row>
    );
};

export default FilterCategoryLoader;