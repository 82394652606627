import {ReactNode} from "react";

interface LanguageProps {
    children: ReactNode,
    linkActiveLanguage: string,
}

const Language = ({children, linkActiveLanguage}: LanguageProps) => {
    const className = "gb--row gb--row-middle gb--row-justify"

    return (
        linkActiveLanguage ? (
            <a href={linkActiveLanguage} className={className}>
                {children}
            </a>
        ) : (
            <span className={className}>
                {children}
            </span>
        )
    )
}

export default Language