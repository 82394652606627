import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import Row from "../../../features/containers/components/Row.tsx";
import Column from "../../../features/containers/components/Column.tsx";
import SuccessfulBookingPrice from "./SuccessfulBookingPrice.tsx";
import useTranslation from "../../../features/translation/hooks/useTranslation.ts";
import BookingAmenitiesBreakdown from "../../bookingForm/features/bookingPriceInfo/BookingAmenitiesBreakdown.tsx";
import PriceDisplay from "../../bookingForm/features/bookingPriceInfo/PriceDisplay.tsx";
import FullScreenLoader from "../../../components/generic/loaders/FullScreenLoader.tsx";
import useCachedData from "../../searchResults/hooks/useCachedData.ts";
import CollapsableComponent from "../../../components/generic/CollapsableComponent.tsx";
import {PaymentMethods} from "../../../constants/PaymentMethods.ts";
import BookingPrepaymentDetails from "../../bookingForm/features/bookingPriceInfo/BookingPrepaymentDetails.tsx";

const SuccessfulBookingInfo = () => {
    const {
        paymentTransferForm,
        amenities: bookingAmenities
    } = useAppSelector((state) => state?.bookingForm);

    const {
        amenities: vehicleAmenities,
        price,
        depositPrice
    } = useAppSelector((state) => state?.vehicleType)

    const {
        bookingId
    } = useAppSelector((state) => state?.stripe);

    const {
        fetchedGbtIsUninitialized: isUninitialized,
        fetchedGbtIsLoading: isLoading,
        fetchedGbtData: data,
    } = useCachedData();


    const totalPrice = vehicleAmenities.reduce((acc, amenity) => {
        const subtotal = (bookingAmenities[amenity.id]?.count + bookingAmenities[amenity.id]?.returnCount) * Number(amenity.price);
        return acc + subtotal;
    }, Number(price));

    const amenitiesPrepayment = vehicleAmenities.reduce((acc, amenity) => {
        const subtotal = (bookingAmenities[amenity.id]?.count + bookingAmenities[amenity.id]?.returnCount) * Number(amenity?.depositPrice);
        return acc + subtotal;
    }, 0)

    const transferInformation = useTranslation("booking.form.transferInfo") as string;
    const totalLabel = useTranslation('booking.confirmation.total') as string;
    const bookingNo = useTranslation('booking.confirmation.bookingNo') as string;
    const priceLabel = useTranslation("booking.form.transferPriceBreakdown") as string;

    const fromLabel = data?.data?.pickupInfo?.pickUpPlace ? data.data.pickupInfo.pickUpPlace : '';
    const toLabel = data?.data?.dropOffInfo?.dropOffPlace ? data.data.dropOffInfo.dropOffPlace : '';

    const fromDisplay = fromLabel.length > 30
        ? `${data.data.pickupInfo.pickUpPlace.substring(0, 37)}...`
        : data.data.pickupInfo.pickUpPlace
    const toDisplay = toLabel.length > 30
        ? `${data.data.dropOffInfo.dropOffPlace.substring(0, 37)}...`
        : data.data.dropOffInfo.dropOffPlace



    if (!isLoading && data && !isUninitialized) {
        return (
            <div>
                <Row className='gb--success-booking__trip'>
                    <Column>
                        <div>
                            <span>{transferInformation}</span>
                        </div>
                    </Column>
                    <Column>
                        <div>
                            <span>{fromDisplay} - {toDisplay}</span>
                        </div>
                    </Column>
                </Row>
                <Row className='gb--success-booking__trip'>
                    {bookingNo}{bookingId}
                </Row>
                <hr/>
                <Column className='gb--success-booking__priceWrapped'>
                    <div style={{padding: "0 0 1rem 0"}}>
                        <CollapsableComponent
                            customDividerClassName={'gb--success-booking-price'}
                            hrClassName={'gb--success-booking-price__divider'}
                            label={priceLabel}
                            collapsable
                            closed
                        >
                            <SuccessfulBookingPrice
                                paymentMethod={paymentTransferForm.paymentMethod.value}
                                displayPrice={Number(price)}
                            />
                            <BookingAmenitiesBreakdown/>
                        </CollapsableComponent>
                    </div>

                    <Row className='gb--success-booking__total'>
                        <Column>
                            {totalLabel}
                        </Column>
                        <Column>
                            <PriceDisplay price={totalPrice}/>
                        </Column>
                    </Row>
                    {paymentTransferForm.paymentMethod.value === PaymentMethods.PREPAYMENT && (
                        <BookingPrepaymentDetails
                            totalPrice={totalPrice}
                            amenitiesDepositPrice={amenitiesPrepayment}
                            depositPrice={Number(depositPrice)}
                        />
                    )}
                </Column>
            </div>
        );
    } else {
        return (<FullScreenLoader/>)
    }
};

export default SuccessfulBookingInfo;