import {lazy, Suspense} from "react"
import {routeIds} from "./routingIds.ts"
import {getTranslatedRoute} from "../functions/getTranslatedRoute.ts"
import {createBrowserRouter} from "react-router-dom"
import BookingNavigation from "../../layout/bookingNavigation/components/BookingNavigation.tsx"
import SearchResultsWrapper from "../../pages/searchResults/components/SearchResultsWrapper.tsx"
import BookingFormWrapper from "../../pages/bookingForm/components/BookingFormWrapper.tsx"
import SuccessfulBooking from "../../pages/successfulBooking/SuccessfulBooking.tsx"
import Layout from "../components/Layout.tsx"
import StationToStationPage from "../../pages/staticPages/stationToStation/StationToStationPage"
import FullScreenLoader from "../../components/generic/loaders/FullScreenLoader.tsx";
import ErrorBoundaryDefault from "../../features/errors/features/errorBoundary/components/ErrorBoundaryDefault.tsx";

// eslint-disable-next-line
const Contact = lazy(() => import("../../pages/contact/components/Contact"))
// eslint-disable-next-line
const FrequentlyAskedQuestions = lazy(() => import("../../pages/faq/components/FrequentlyAskedQuestions.tsx"))

const Home = lazy(() => import('../../pages/homepage/components/Homepage.tsx'))

const {homepage, staticPages, booking, vehicle, bookingInfo, successful, contact, faq, termsAndConditions, privacyPolicy} = routeIds

const {getPath} = getTranslatedRoute()

export const browserRouter = createBrowserRouter([
    {
        path: "/:locale?",
        element: <Layout />,
        errorElement: <ErrorBoundaryDefault shouldLogError/>,
        children: [
            {
                id: homepage,
                index: true,
                element:
                    <Suspense fallback={<FullScreenLoader/>}>
                        <Home />
                    </Suspense>,
            },
            {
                id: contact,
                path: getPath("contact"),
                element:
                    <Suspense fallback={<FullScreenLoader/>}>
                        <Contact />
                    </Suspense>,
            },
            {
                id: faq,
                path: getPath("faq"),
                element:
                    <Suspense fallback={<FullScreenLoader/>}>
                        <FrequentlyAskedQuestions />
                    </Suspense>,
            },
            {
                id: privacyPolicy,
                path: getPath("privacyPolicy"),
                element: null,
            },
            {
                id: termsAndConditions,
                path: getPath("termsAndConditions"),
                element: null,
            },
            {
                id: staticPages,
                path: ":staticPage",
                element: <StationToStationPage /> ?? null,
            },
            {
                id: booking,
                path: getPath("booking"),
                element: <BookingNavigation />,
                errorElement: <ErrorBoundaryDefault shouldLogError/>,
                children: [
                    {
                        id: vehicle,
                        path: ":fromSlug/:toSlug/:date/:passengers",
                        element: <SearchResultsWrapper />,
                    },
                    {
                        id: bookingInfo,
                        path: ":fromSlug/:toSlug/:date/:passengers/:vehicleGroupId",
                        element: <BookingFormWrapper />,
                    },
                    {
                        id: successful,
                        path: "successful-booking",
                        element: <SuccessfulBooking />,
                    },
                ],
            },
            {
                path: `*`,
                element: null,
            },
        ],
    },
])
