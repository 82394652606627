import CollapsableComponent from "../../../../../components/generic/CollapsableComponent.tsx";
import Row from "../../../../../features/containers/components/Row.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";
import FlightNumber from "../features/FlightNumber/components/FlightNumber.tsx";
import ReturnFlightNumber from "../features/ReturnFlightNumber/components/ReturnFlightNumber.tsx";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";

interface BookingFlightNumber {
    data: any
}

const BookingFlightNumber = (props: BookingFlightNumber) => {
    //TODO insert translation
    const flightNumberLabel = useTranslation("booking.form.flightNumberLabel") as string

    const {
        data
    } = props


    return (
        <div className={'gb--booking-item'}>
            <CollapsableComponent label={flightNumberLabel} hrClassName={'gb--divider__hr'}>
                <Row
                    justify
                    className="gb--booking__input"
                >
                    <Column  className="gb--booking__container">
                        <FlightNumber data={data}/>
                    </Column>
                    <Column className="gb--booking__container">
                        {data.isRoundTrip && (
                            <ReturnFlightNumber data={data}/>
                        )}
                    </Column>
                </Row>
            </CollapsableComponent>
        </div>
    );
};

export default BookingFlightNumber;