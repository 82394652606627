import useBookingInfoInput from "../features/bookingInfoInputField/hooks/useBookingInfoInput.ts"
import {CountryType, emptyCountryObject} from "@getbybus/gbb-green"
import {useEffect, useState} from "react"
import lodash from "lodash"
import useTranslation from "../../../features/translation/hooks/useTranslation.ts"
import {setContactFormData, setCountry, setCountryCode, setIsoCode} from "../reducers/functions/bookingSlice.ts"
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts"
import {CountryCode, parsePhoneNumber} from "libphonenumber-js/max";
import {isPossiblePhoneNumber} from "libphonenumber-js";

export const usePhone = () => {
    const dispatch = useAppDispatch()

    const {
        value: phone,
        displayError,
        errorMessage,
    } = useBookingInfoInput({
        formType: "contactInformationForm",
        fieldValidationMethod: () => validatePhoneNumber(),
        field: "phoneNumber",
    })

    const [countryPhone, setCountryPhone] = useState<CountryType>(emptyCountryObject)

    const errorMessagePhone = useTranslation(errorMessage) as string

    const validatePhoneNumber = () => {
        if (countryPhone.isoCode && phone.length > 5 && phone.length < 22 && isPossiblePhoneNumber(phone, countryPhone.isoCode as CountryCode)) {
            const phoneNumber = parsePhoneNumber(phone, countryPhone.isoCode as CountryCode)
            return !!phoneNumber.country && phoneNumber.isValid() && phoneNumber.isPossible()
        }

        return false

    }

    const setPhoneHandler = (value: string) =>
        dispatch(
            setContactFormData({
                value,
                displayError,
                isValid: validatePhoneNumber(),
                type: "phoneNumber",
            }),
        )

    useEffect(() => {
        if (!lodash.isEqual(countryPhone, emptyCountryObject)) {
            dispatch(
                setContactFormData({
                    type: "phoneNumber",
                    value: phone,
                    displayError: !validatePhoneNumber(),
                    isValid: validatePhoneNumber(),
                }),
            )
        }
    }, [phone])

    const setCountryPhoneHandler = (country: CountryType) => {
        setCountryPhone(country)
        dispatch(setCountry(country.name))
        dispatch(setCountryCode(country.dialCode))
        dispatch(setIsoCode(country.isoCode))
    }
    const onBlur = () => {
        // dispatch(
        //     setContactFormData({
        //         type: "phoneNumber",
        //         value: phone,
        //         displayError: !validatePhone(phone),
        //         errorMessage,
        //         isValid: validatePhone(phone),
        //     }),
        // )
    }

    return {
        countryPhone,
        setCountryPhone,
        errorMessagePhone,
        setPhoneHandler,
        setCountryPhoneHandler,
        onBlur,
        phone,
        displayError,
    }
}
