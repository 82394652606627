import SuccessfulBookingThankYou from "./components/SuccessfulBookingThankYou.tsx";
import SuccessfulBookingCard from "./components/SuccessfulBookingCard.tsx";
import SuccessfulBookingButton from "./components/SuccessfulBookingButton.tsx";
import {useEffect} from "react";
import {useLocation} from "react-router";
import {useAppDispatch} from "../../hooks/dispatch/useAppDispatch.ts";
import {setIsBooked} from "../bookingForm/reducers/functions/bookingSlice.ts";

const SuccessfulBooking = () => {
    const location = useLocation();
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(setIsBooked(new Date().toISOString()))
        if (location.state && location.state?.data.googleAdsTransactionDataLayer) {
            window.dataLayer.push({ ecommerce: null });
            // Dispatch google tag manager object received from the successful booking if the payment method includes a card payment option
            window.dataLayer.push(location.state.data.googleAdsTransactionDataLayer);
        }

    }, []);

    return (
        <div className='gb--success-booking'>
            <SuccessfulBookingThankYou/>
            <SuccessfulBookingCard/>
            <SuccessfulBookingButton/>
        </div>
    );
};

export default SuccessfulBooking;