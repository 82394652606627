import PoolMozioSearchResultsResponseType from "../types/PoolMozioSearchResultsResponse.type.ts";

export default function getMozioResultsHandler(data: PoolMozioSearchResultsResponseType) {
    // const
    const handleNewBatchOfMozioResults = (state: PoolMozioSearchResultsResponseType) => {
        return ({
            ...state,
            data: {
                ...state.data,
                moreComing: data.data.moreComing,
                results: [...state.data.results, ...(!!data.data.results ? data.data.results : [])],
            }
        })
    }

    return {
        handleNewBatchOfMozioResults,
    }
}