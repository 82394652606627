import useDismissAllOverlays from "../hooks/useDismissAllOverlays";
import MobileOverlay from "./MobileOverlay";
import {setReturnTrip} from "../../../pages/bookingForm/reducers/functions/bookingSlice.ts";
import {setReturnCalendar} from "../../../reducers/mobileSlice.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import useReturnDateSelectHandlers from "../../../layout/form/features/dates/hooks/useReturnDateSelectHandlers.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import DateTimeSelect from "../../dateTime/components/DateTimeSelect.tsx";
import useTranslation from "../../translation/hooks/useTranslation.ts";
import useIsOverlaySubtreeActive from "../hooks/useIsOverlaySubtreeActive.ts";
import {add} from "date-fns";

function ReturnMobileDatePicker() {
    const {
        departure,
        setIsDropdownOpen,
        setDeparture,
        selectReturnDate,
    } = useReturnDateSelectHandlers()
    const {departureDate} = useAppSelector((state) => state?.form)

    const isOverlaySubtreeActive = useIsOverlaySubtreeActive()
    const dispatch = useAppDispatch();
    const dismissAllOverlays = useDismissAllOverlays();

    const backClick = () => {
        if (isOverlaySubtreeActive) {
            dispatch(setReturnCalendar(false))
        } else {
            dismissAllOverlays()
        }
    }

    const handleSelect = (value: Date) => {
        if (isOverlaySubtreeActive) {
            dispatch(setReturnCalendar(false))
        } else {
            dismissAllOverlays();
        }
        selectReturnDate(value);
        dispatch(setReturnTrip(true));
    };

    const selectDateLabel = useTranslation("search.form.date") as string
    const selectDateSubtitle = useTranslation("search.form.text") as string

    return (
        <MobileOverlay title={selectDateLabel} backClick={backClick} subtitle={selectDateSubtitle}>
            <DateTimeSelect
                selectedDate={departure ? new Date(departure) : add(new Date(), {hours: 2}).toISOString()}
                selectedTime={departure ? new Date(departure) : add(new Date(), {hours: 2}).toISOString()}
                changeDate={(value: Date) => {
                    setDeparture(new Date(value).toISOString())
                }}
                changeTime={(value: Date) => {
                    console.log('uslo')
                    setDeparture(new Date(value).toISOString())
                }}
                submitSelect={handleSelect}
                dateLabel={'Date'}
                handleClose={() => {
                    setIsDropdownOpen(false)
                }}
                isClosable={true}
                timeLabel={'Time'}
                minDateTime={add(new Date(departureDate || new Date().toISOString()), {hours: 2})}
            />
        </MobileOverlay>
    );
}

export default ReturnMobileDatePicker