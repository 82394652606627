import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    id: 0,
    imagePath: '',
    maxPax: 0,
    price: 0,
    paymentMethods: {},
    type: '',
    duration: 0,
    minPax: 0,
    prepaymentRate: 0,
    isIntegration: false,
    amenities: []
}

const vehicleTypeSlice = createSlice({
    name: 'vehicleType',
    initialState,
    reducers: {
        setVehicleTypeId: (state, action) => (
            {...state, id: action.payload}
        ),
        setImagePath: (state, action) => (
            {...state, imagePath: action.payload}
        ),
        setPaxNumber: (state, action) => (
            {...state, maxPax: action.payload}
        ),
        setPrice: (state, action) => (
            {...state, price: action.payload}
        ),
        setType: (state, action) => (
            {...state, type: action.payload}
        ),
        setPaymentMethods: (state, action) => (
            {...state, paymentMethods: action.payload}
        ),
        setDuration: (state, action) => (
            {...state, duration: action.payload}
        ),
        setPrepaymentRate: (state, action) => (
            {...state, prepaymentRate: action.payload}
        ),
        setVehicleType: (state, action) => (
            {
                ...state, ...action.payload
            }
        ),
        setIsIntegration: (state, action) => (
            {...state, isIntegration: action.payload}
        ),
        clear: () => initialState,
    },
})

export const {
    setVehicleTypeId,
    setIsIntegration,
    setType,
    setPrice,
    setPaxNumber,
    setImagePath,
    setVehicleType,
    setPaymentMethods,
    setDuration,
    setPrepaymentRate
} = vehicleTypeSlice.actions

export default vehicleTypeSlice.reducer