import FloatingLabelInput from "./FloatingLabelInput.tsx"
import ErrorNotification from "../../../pages/bookingForm/features/bookingInfoInputField/components/ErrorNotification.tsx"
import Column from "../../../features/containers/components/Column.tsx"
import {ChangeEvent, FocusEvent, ReactNode} from "react"

interface FloatingLabelInputFieldProps {
    type: "text" | "number" | "email" | "phone"
    id: string
    label: string
    value: string
    icon?: ReactNode
    onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    displayError?: boolean
    errorMessage?: string
    maxLength?: number
    background: 'white' | 'blue'
}

function FloatingLabelInputField(props: FloatingLabelInputFieldProps) {
    const {type,
        id,
        label,
        value,
        icon,
        onBlur,
        onChange,
        displayError,
        errorMessage,
        maxLength,
        background = 'blue'
    } = props

    return (
        <Column className="gb--booking__container">
            <FloatingLabelInput
                onBlur={onBlur}
                value={value!}
                icon={icon}
                id={id}
                label={label}
                type={type}
                onChange={onChange}
                maxLength={maxLength}
                background={background}
            />
            {displayError && <ErrorNotification errorMessage={errorMessage!} />}
        </Column>
    )
}

export default FloatingLabelInputField
