import {useToggle} from "@getbybus/gbb-green";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {useEffect, useRef} from "react";
import CustomDropdown from "../../../../../components/generic/CustomDropdown.tsx";
import IconLocationMarker from "../../../../../components/generic/icons/IconLocationMarker.tsx";
import FadingError from "../../../../../features/errors/components/FadingError.tsx";
import useHandleUserPlaceInput from "../hooks/useHandleUserPlaceInput.ts";
import useHandleUserPlaceSelect from "../hooks/useHandleUserPlaceSelect.ts";
import PlaceSelectOptions from "./PlaceSelectOptions.tsx";
import usePlacePlaceholder from "../hooks/usePlacePlaceholder.ts";
import ValidPlaceIndicator from "./ValidPlaceIndicator.tsx";

interface PlaceSelectProps {
    direction: "to" | "from",
    name: "departure" | "destination",
    label?: string,
    placeholder: string,
    isOverlay?: boolean,
    showIsValid?: boolean,
    searchStrict?: boolean,
    isValid?: boolean,
    showIsIncomplete?: boolean,
    data?: any
}

const PlaceSelect = (props: PlaceSelectProps) => {
    const {
        name,
        label,
        placeholder,
        direction,
        searchStrict,
        isOverlay = false,
        showIsValid = false,
        isValid,
        showIsIncomplete,
        data
    } = props

    const {
        formErrors,
        submitTimestamp,
        fromStation,
        toStation
    } = useAppSelector((state) => (state?.form))

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isOverlay) {
            setTimeout(() => {
                inputRef?.current?.focus();
            },0)
        }
    }, [isOverlay]);

    const error = direction === 'from' ? formErrors.departureError : formErrors.destinationError
    const errorMessage = direction === 'from' ? 'search.form.departureError' : 'search.form.destinationError'

    const {
        placePlaceholder,
        setPlacePlaceholder,
    } = usePlacePlaceholder(name)

    const {
        set: setIsDropdownOpen,
        value: isDropdownOpen,
    } = useToggle()

    const {
        response,
        handleChange,
    } = useHandleUserPlaceInput({setIsDropdownOpen, setPlacePlaceholder, searchStrict, name, fromStation, toStation, data})

    const {
        handleSelect,
    } = useHandleUserPlaceSelect({name, response, setIsDropdownOpen, setPlacePlaceholder})

    const handleClickOutside = () => {
        if (isDropdownOpen) {
            setIsDropdownOpen(false)
        }
    }

    return (
        <div className={`${label ? "search__select" : "search__select__center"} search__select--${direction}`}>
            <CustomDropdown
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
                handleClickOutside={handleClickOutside}
                additionalClasses={`${isOverlay ? 'search__select-container' : ''} ${showIsIncomplete ? "search__select--incomplete-address-border" : ""}`}
                field={(
                    <>
                        {showIsIncomplete && (
                            <div className="search__select--incomplete-address-container">
                                <div className="search__select--incomplete-address">
                                    Update address
                                </div>
                            </div>
                        )}
                        <IconLocationMarker
                            className={label ? 'search__select--icon' : 'search__select--icon__center'}
                        />
                        <input
                            ref={inputRef}
                            onChange={handleChange}
                            type="text"
                            name={name}
                            className={"gb--input-standalone"}
                            placeholder={placeholder}
                            value={placePlaceholder}
                            autoComplete={"off"}
                        />
                        {showIsValid && (
                            <ValidPlaceIndicator isValid={isValid!}/>
                        )}
                        {!!label && (
                            <span className="search__select--label gb--emphasize gb--emphasize-default">
                                {label} {/* Will add translations later on*/}
                            </span>
                        )}
                    </>
                )}
            >
                <PlaceSelectOptions
                    isLoading={response.isLoading}
                    data={response?.data}
                    handleSelect={handleSelect}
                />
            </CustomDropdown>
            <FadingError
                isError={error}
                showError={submitTimestamp}
                errorMessageTranslationKey={errorMessage}
            />
        </div>
    )
}

export default PlaceSelect
