import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import PlaceSelect from "./PlaceSelect.tsx";
import useFormPlaceValidator from "../../../../../pages/searchResults/hooks/useFormPlaceValidator.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";

interface StationSelectorsProps {
    showIsValid?: boolean
    searchStrict?: boolean,
    showIsIncomplete?: boolean,
    data?: any
}

function PlaceSelectors({showIsValid = false, searchStrict, showIsIncomplete, data}: StationSelectorsProps) {
    const {
        pickUpLocation: pickUpLocationLabel,
        destinationLocation: destinationLocationLabel,
        from: fromLabel,
        to: toLabel,
    } = useTranslation('search.form') as Record<any, string>

    const {invalidDeparture, invalidDestination, areSame} = useFormPlaceValidator()
    const {
        fromPlace,
        toPlace,
    } = useAppSelector((state) => (state.form))

    return (
        <>
            <div className='search__select search__select--from'>
                <PlaceSelect
                    direction='from'
                    name='departure'
                    label={pickUpLocationLabel}
                    placeholder={fromLabel}
                    showIsValid={showIsValid}
                    isValid={!invalidDeparture && !areSame}
                    searchStrict={searchStrict}
                    showIsIncomplete={showIsIncomplete && !!fromPlace && !fromPlace.placeId}
                    data={data}
                />
            </div>
            <div className='search__select search__select--to'>
                <PlaceSelect
                    direction='to'
                    name='destination'
                    label={destinationLocationLabel}
                    placeholder={toLabel}
                    showIsValid={showIsValid}
                    isValid={!invalidDestination && !areSame}
                    searchStrict={searchStrict}
                    showIsIncomplete={showIsIncomplete && !!toPlace && !toPlace.placeId}
                    data={data}
                />
            </div>
        </>
    );
}

export default PlaceSelectors;