import useLocale from "../../../../../hooks/selector/useLocale.ts";
import {apiSlice} from "../../../../../api/reducers/apiSlice.ts";

export default function useCachedMozioData(searchId: string) {
    const locale = useLocale()

    const {
        data,
        error,
        isError,
        isFetching,
        isLoading,
        isUninitialized,
        status,
    } = apiSlice.endpoints?.pollMozioResults.useQueryState({
        locale,
        searchId,
    })

    return {
        fetchedMozioData: data,
        fetchedMozioError: error,
        fetchedMozioIsError: isError,
        fetchedMozioIsLoading: isFetching || isLoading,
        fetchedMozioIsUninitialized: isUninitialized,
        fetchedMozioStatus: status,
    }
}