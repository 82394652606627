import SearchResultsSliceType from "../types/SearchResultsSlice.type.ts";

export const searchResultsSliceInitialState: SearchResultsSliceType = {
    unsuccessfulMozioFetchCount: 0,
    successfulMozioFetchCount: 0,
    shouldUseStationParams: true,
    searchedWithStations: false,
    selectedVehicleCategory: 0,
    categoryPrices: {},
    preselectCategory: true
}