import {useEffect} from "react";
import {maxConsequentFailedMozioFetchCount} from "../constants/maxConsequentFailedMozioFetchCount.ts";
import {usePollMoreMozioResultsMutation} from "../../../../../api/reducers/apiSlice.ts";
import useLocale from "../../../../../hooks/selector/useLocale.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {InitialSearchRequestType} from "../../../../../api/types/InitialSearchRequest.type.ts";

export default function useGetMoreOfMozioData(data: any, gbtVehiclesData: any, originalArgs: InitialSearchRequestType) {
    const [getMoreMozioVehicles] = usePollMoreMozioResultsMutation()
    const locale = useLocale();
    const unsuccessfulMozioFetchCount = useAppSelector((state) => (state.searchResults.unsuccessfulMozioFetchCount))

    useEffect(() => {
        // if sequential mozio fetch more failed count gets to 3, stop fetching more mozio data
        if (data?.data?.moreComing && unsuccessfulMozioFetchCount !== maxConsequentFailedMozioFetchCount) {
            const timer = setTimeout(() => {
                getMoreMozioVehicles({
                    searchId: gbtVehiclesData?.data?.mozioSearchId,
                    locale,
                })
            }, 3000 + ((unsuccessfulMozioFetchCount * 1000)))
            return () => clearTimeout(timer);
        }
    }, [data]);
}