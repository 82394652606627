import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import StationType from "../layout/form/features/stations/types/Station.type.ts";
import FormErrorsType from "../layout/form/types/FormErrors.type.ts";
import {PlaceType} from "../types/Place.type.ts";

interface FormSliceStateType {
    fromPlace: PlaceType | null;
    toPlace: PlaceType | null;
    fromStation: StationType | null;
    toStation: StationType | null;
    departureDate: string;
    returnDate: string | null;
    numberOfPassengers: number;
    formErrors: FormErrorsType,
    submitTimestamp: number | null,
    successfulSubmitTimestamp: number | null,
    searchFinishedTimestamp: number | null,
}

const initialState: FormSliceStateType = {
    fromPlace: null,
    toPlace: null,
    fromStation: null,
    toStation: null,
    departureDate: '',
    returnDate: null,
    numberOfPassengers: 1,
    formErrors: {
        departureError: null,
        destinationError: null,
        dateError: null,
        passengerError: null,
    },
    submitTimestamp: null,
    successfulSubmitTimestamp: null,
    searchFinishedTimestamp: null,
}

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        setFromPlace: (state, action: PayloadAction<PlaceType>) => (
            {...state, fromPlace: action.payload}
        ),
        setToPlace: (state, action: PayloadAction<PlaceType>) => (
            {...state, toPlace: action.payload}
        ),
        setFromStation: (state, action: PayloadAction<StationType>) => (
            {...state, fromStation: action.payload}
        ),
        setToStation: (state, action: PayloadAction<StationType>) => (
            {...state, toStation: action.payload}
        ),
        clearStations: (state) => (
            {...state, fromStation: null, toStation: null}
        ),
        setDepartureDate: (state, action: PayloadAction<string>) => (
            {...state, departureDate: action.payload}
        ),
        setReturnDate: (state, action: PayloadAction<string | null>) => (
            {...state, returnDate: action.payload}
        ),
        setNumberOfPassengers: (state, action: PayloadAction<number>) => (
            {...state, numberOfPassengers: action.payload}
        ),
        setFormErrors: (state, action: PayloadAction<FormErrorsType>) => ({
            ...state,
            formErrors: action.payload,
            submitTimestamp: Date.now(),
        }),
        setSearchFinishedTimestamp: (state, action: PayloadAction<number>) => ({
            ...state,
            searchFinishedTimestamp: action.payload,
        }),
        setSuccessfulSubmitTimestamp: (state) => ({
            ...state,
            successfulSubmitTimestamp: Date.now(),
        }),
        clearFormErrors: (state) => ({
            ...state,
            formErrors: {
                departureError: null,
                destinationError: null,
                dateError: null,
                passengerError: null,
            },
            submitTimestamp: null,
        }),
        clear: () => initialState,
    },
})

export const {
    clearStations,
    setFromPlace,
    setToPlace,
    setFromStation,
    setNumberOfPassengers,
    setDepartureDate,
    setReturnDate,
    setToStation,
    setFormErrors,
    setSuccessfulSubmitTimestamp,
    setSearchFinishedTimestamp,
    clearFormErrors,
} = formSlice.actions

export default formSlice.reducer